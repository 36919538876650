import { SummarizationTypes } from "../pages/ToolsPage/components/DocumentSummarization/helpers/summarizationTypes.enum";

export class MetadataTemplate {
  id: string | number;
  name: string;
  contract_type: { id: string | number; name: string } | undefined;
  metadata: Array<{
    id: number | string;
    name: string;
    description: string;
    type: string;
    output_format: string;
  }> = [];
  custom_metadata: Array<{
    id: number | string;
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }> = [];
  language: string = "English";

  constructor(
    id: string | number,
    name: string,
    contract_type: { id: string | number; name: string } | undefined,
    metadata: Array<{
      id: number | string;
      name: string;
      description: string;
      type: string;
      output_format: string;
    }> = [],
    custom_metadata: Array<{
      id: number | string;
      metadata_parameter: string;
      description: string;
      metadata_type: string;
      output_format: string;
    }> = [],
    language: string = "English"
  ) {
    this.id = id;
    this.name = name;
    this.contract_type = contract_type;
    this.metadata = metadata;
    this.custom_metadata = custom_metadata;
    this.language = language;
  }
}

export class ObligationTemplate {
  id: string | number;
  name: string;
  contract_type: { id: string | number; name: string } | undefined;
  obligations: Array<{
    id: number | string;
    name: string;
    description: string;
  }> = [];
  custom_obligations: Array<{ id: number | string; name: string }> = [];
  language: string = "English";

  constructor(
    id: string | number,
    name: string,
    contract_type: { id: string | number; name: string } | undefined,
    obligations: Array<{
      id: number | string;
      name: string;
      description: string;
    }> = [],
    custom_obligations: Array<{ id: number | string; name: string }> = [],
    language: string = "English"
  ) {
    this.id = id;
    this.name = name;
    this.contract_type = contract_type;
    this.obligations = obligations;
    this.custom_obligations = custom_obligations;
    this.language = language;
  }
}

export class RiskTemplate {
  id: string | number;
  name: string;
  contract_type: { id: string | number; name: string } | undefined;
  risks: Array<{ id: number | string; name: string; description: string }> = [];
  custom_risks: Array<{ id: number | string; name: string }> = [];
  language: string = "English";

  constructor(
    id: string | number,
    name: string,
    contract_type: { id: string | number; name: string } | undefined,
    risks: Array<{
      id: number | string;
      name: string;
      description: string;
    }> = [],
    custom_risks: Array<{ id: number | string; name: string }> = [],
    language: string = "English"
  ) {
    this.id = id;
    this.name = name;
    this.contract_type = contract_type;
    this.risks = risks;
    this.custom_risks = custom_risks;
    this.language = language;
  }
}

export class SummarizationTemplate {
  id: string | number;
  name: string;
  team: string | number;
  type: SummarizationTypes;
  length_of_summary: string;
  custom_prompt: string | undefined;
  output_format: string;
  first_language: string = "English";
  second_language: string;

  constructor(
    id: string | number,
    name: string,
    team: string | number,
    type: SummarizationTypes,
    length_of_summary: string,
    custom_prompt: string | undefined,
    output_format: string,
    first_language: string = "English",
    second_language: string
  ) {
    this.id = id;
    this.name = name;
    this.team = team;
    this.type = type;
    this.length_of_summary = length_of_summary;
    this.custom_prompt = custom_prompt;
    this.output_format = output_format;
    this.first_language = first_language;
    this.second_language = second_language;
  }
}

export class ComparisonPlaybookTemplate {
  id: string | number;
  name: string;
  file: File;
  specific_clauses_to_check: string = "";
  language: string;

  constructor(
    id: string | number,
    name: string,
    file: File,
    specific_clauses_to_check: string = "",
    language: string
  ) {
    this.id = id;
    this.name = name;
    this.file = file;
    this.specific_clauses_to_check = specific_clauses_to_check;
    this.language = language;
  }
}

export class ComparisonRegulationCustomTemplate {
  id: string | number;
  name: string;
  file: File;
  specific_clauses_to_check: string = "";
  language: string;

  constructor(
    id: string | number,
    name: string,
    file: File,
    specific_clauses_to_check: string = "",
    language: string
  ) {
    this.id = id;
    this.name = name;
    this.file = file;
    this.specific_clauses_to_check = specific_clauses_to_check;
    this.language = language;
  }
}

export class ComparisonRegulationPredefinedTemplate {
  id: string | number;
  name: string;
  file: { id: number; name: string };
  specific_clauses_to_check: string = "";
  language: string;

  constructor(
    id: string | number,
    name: string,
    file: { id: number; name: string },
    specific_clauses_to_check: string = "",
    language: string
  ) {
    this.id = id;
    this.name = name;
    this.file = file;
    this.specific_clauses_to_check = specific_clauses_to_check;
    this.language = language;
  }
}

export class ComparisonContractTemplate {
  id: string | number;
  name: string;
  file: File;
  specific_clauses_to_check: string = "";
  language: string;

  constructor(
    id: string | number,
    name: string,
    file: File,
    specific_clauses_to_check: string = "",
    language: string
  ) {
    this.id = id;
    this.name = name;
    this.file = file;
    this.specific_clauses_to_check = specific_clauses_to_check;
    this.language = language;
  }
}

export class ComparisonRedliningTemplate {
  id: string | number;
  name: string;
  file: File;
  specific_clauses_to_check: string = "";
  language: string;

  constructor(
    id: string | number,
    name: string,
    file: File,
    specific_clauses_to_check: string = "",
    language: string
  ) {
    this.id = id;
    this.name = name;
    this.file = file;
    this.specific_clauses_to_check = specific_clauses_to_check;
    this.language = language;
  }
}

export class ComparisonChecklistCustomTemplate {
  id: string | number;
  name: string;
  file: File;
  specific_clauses_to_check: string = "";
  language: string;

  constructor(
    id: string | number,
    name: string,
    file: File,
    specific_clauses_to_check: string = "",
    language: string
  ) {
    this.id = id;
    this.name = name;
    this.file = file;
    this.specific_clauses_to_check = specific_clauses_to_check;
    this.language = language;
  }
}

export class ComparisonChecklistPredefinedTemplate {
  id: string | number;
  name: string;
  file: { id: number; name: string };
  specific_clauses_to_check: string = "";
  language: string;

  constructor(
    id: string | number,
    name: string,
    file: { id: number; name: string },
    specific_clauses_to_check: string = "",
    language: string
  ) {
    this.id = id;
    this.name = name;
    this.file = file;
    this.specific_clauses_to_check = specific_clauses_to_check;
    this.language = language;
  }
}
