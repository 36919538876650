// @ts-nocheck
import { ProChat } from "@ant-design/pro-chat";
import { Input, List, Select, Tabs, theme } from "antd";
import { ThemeProvider, css, cx } from "antd-style";
import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { BsStars } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import MainContext from "../../contexts/MainContext";
import FilesContext from "../../contexts/FilesContext";
import AIAgentFeedback from "./components/AIAgentFeedback/AIAgentFeedback";
import styles from "./SearchPage.module.css";
import { useNavigate } from "react-router-dom";
/* tslint:disable */
import * as FilesService from "../FilesPage/services/files.service";

import { Flex, Spin } from "antd";
import { MentionsInput, Mention } from "react-mentions";
import { Button } from "antd";
import { SendOutlined, StopOutlined, ClearOutlined } from "@ant-design/icons";
const nonBreakingSpace = String.fromCharCode(160);

const initMessage = ` Welcome to the AI Agent, your chat interface for interacting with your documents using AI.

How to Use the AI Agent?

1. **Selecting a Project**: Use the left sidebar to choose a project. This allows you to chat with all the documents you've uploaded to that project. You can ask questions without mentioning specific documents, and the AI will provide relevant information from your files.

2. **Specific Document Interaction**: To chat with a particular document, press the slash key ("/") and select the document from the current selected project or press "#" to select document from library you wish to reference.

3. **Use integrated databases**: You can ask questions about the integrated databases, press "@" to mention them.

4. **Using Documents as Examples**: You can also refer to documents not only as data sources but as examples or templates for your tasks.

5. **Practicing Prompts**: Get familiar with our chat solutions by practicing different prompts. This will help you maximize the AI's capabilities.

6. **Content**: You can ask to generate documents, summarize, or extract information from your documents. It can return tables as well.



**Privacy Note**: Please note that chat history is not saved to ensure your privacy and security. Enjoy exploring your data with the AI Agent!`;

const backendUrl = process.env.REACT_APP_API_URL;
const { Search } = Input;
const { Option } = Select;

const delay = (text: string) =>
  new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve(text);
    }, 5000);
  });
const CustomClassName = cx(
  css(`
    .ant-pro-chat-list-item-message-content{
      color: #FFFFFF!important;
    }
    .ant-pro-chat-input-area
    {
      background-image:none;
      background-color: none;
      border-radius: 12px!important;
    }
    .ant-pro-chat{
      flex-grow: 1;
    }
     p{
      word-wrap: normal!important;
      word-break: auto-phrase!important;
    }
  
    
`)
);
interface SearchResult {
  fileName: string;
  highlightedText: string;
  tags: string[];
}

const SearchInterface = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [query, setQuery] = useState('');
  const mainContext = useContext(MainContext);

  const project = mainContext.searchProjectSelected
    ? mainContext.searchProjectSelected
    : "all";

  const setProject = (value) => {
    mainContext?.setSearchProjectSelected(value);
    if (value == "eureka" || value == "all") {
      return;
    }
    mainContext?.setProjectSelected({ id: value });
    navigate(`/${mainContext?.teamSelected}/${value}/search`);
  };
  const projects = [{ id: "eureka", name: "Eureka DataBase" }].concat(
    mainContext?.projects
  );

  const handleSearch = async (page: number) => {
    setLoading(true);
    //console.log(`Searching '${mainContext?.searchQuery}' in '${project}'`);
    // const page = mainContext?.searchPage;
    var response = await mainContext?.handleSearchRequest(
      mainContext?.searchQuery,
      project,
      page || 0
    );
    var data = await response?.json();
    data.results.forEach((item: any) => {
      item.SP_path = item.SP_path.replace(
        "https://documentor.blob.core.windows.net/media/",
        ""
      );
      item["caption"] = item["@search.captions"].reduce(
        (acc: any, caption: any) => {
          return acc + (caption.highlights && caption.highlights.length > 0)
            ? caption.highlights
            : caption.text + "<br/>";
        },
        ""
      );
      item["highlightedText"] = item["@search.captions"][0].text;
    });
    mainContext?.setSearchResults(data.results);
    mainContext?.setSearchPagesNum(data.count);
    setLoading(false);
  };

  return (
    <>
      <div className={styles.searchbar}>
        <Search
          placeholder="Enter search query"
          enterButton="Search"
          value={mainContext?.searchQuery}
          onSearch={async () => {
            await mainContext?.setSearchPage(0);
            await handleSearch(0);
          }}
          onChange={(e) => {
            mainContext?.setSearchQuery(e.target.value);
          }}
          // onClick={handleSearch}
          style={{ width: 500, marginBottom: 8 }} // Changed width to 500px and added margin below
        />
        <Select
          id="project-select"
          defaultValue="all"
          value={project}
          style={{ width: 120, marginBottom: 8, marginLeft: 10 }}
          onChange={setProject}
        >
          <Option value="all">All Projects</Option>
          {projects?.map((project) => (
            <Option value={project.id}>{project.name}</Option>
          ))}
        </Select>
      </div>
      {loading ? (
        <Flex
          style={{ height: "20vh" }}
          align="center"
          gap="middle"
          justify="center"
        >
          <Spin size="large" />
        </Flex>
      ) : (
        <DisplayResults handleSearch={handleSearch} />
      )}
    </>
  );
};

interface DisplayResultsProps {
  handleSearch: (page: number) => void; // define the type of handleFunction here
}

const DisplayResults: React.FC<DisplayResultsProps> = ({ handleSearch }) => {
  const mainContext = useContext(MainContext);
  const data = mainContext?.searchResults;

  const project = mainContext ? mainContext.projectSelected : "all";
  const setProject = mainContext?.setSearchProjectSelected;
  const projects = mainContext?.projects;

  return (
    <div className={styles.searchresults}>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: async (page) => {
            await mainContext?.setSearchPage(page - 1);
            //console.log(`Page: ${page}, ${mainContext?.searchPage}`);
            await handleSearch(page - 1);
          },
          pageSize: 10,
          defaultCurrent: mainContext?.searchPage
            ? mainContext?.searchPage + 1
            : 1,
          total: mainContext?.searchPagesNum,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              title={
                item.case_no ? (
                  <a
                    style={{ fontWeight: "bold" }}
                    href={item.SP_path}
                    target="_blank"
                  >
                    {item.case_no}: {item.title}
                  </a>
                ) : (
                  <a
                    onClick={async () => {
                      mainContext?.setTextViewContent({
                        text: undefined,
                      });
                      mainContext?.setIsTextViewVisible(true);

                      let textResponse = await mainContext?.get_doc_text(
                        item.id,
                        undefined
                      );

                      mainContext?.setTextViewContent({
                        text: textResponse["text"],
                        title: item.SP_path.replace(
                          "https://documentor.blob.core.windows.net/media/",
                          ""
                        ),
                        scrollToText: item["highlightedText"],
                      });
                    }}
                    href="#"
                  >
                    {item.SP_path.replace(
                      "https://documentor.blob.core.windows.net/media/",
                      ""
                    )}
                  </a>
                )
              }
              description={item.date ? item.date.replace("T00:00:00Z", "") : ""}
            />
            <div
              className={styles.searchResult}
              dangerouslySetInnerHTML={{ __html: item.caption }}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

const CustomInput = ({
  value,
  onChange,
  onSend,
  isProcessing,
  projectFiles,
  libraryFiles,
  onStop,
}) => {
  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!isProcessing) {
        onSend();
      }
    }
  };

  return (
    <div
      style={{
        position: "relative",
        height: "80px",
        backgroundColor: "#262730",
        borderRadius: "8px",
        border: "1px solid #444",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        id="listboxContainer"
        style={{
          flexGrow: 1,
          height: "80px",
          // overflow: 'hidden', // Ensure the child doesn't overflow
          padding: "12px",
          paddingRight: "40px",
          // position: "relative",
        }}
      >
        <MentionsInput
          inputRef={inputRef}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          forceSuggestionsAboveCursor={true}
          renderSuggestionsContainer={({ children }) => (
            <div className={styles.listbox}>{children}</div>
          )}
          style={{
            control: {
              fontFamily:
                "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
              fontSize: "14px",
              lineHeight: "20px",
              height: "60px", // Constrain the height
              width: "100%",
              overflow: "auto", // Enable scrolling within MentionsInput
              position: "relative",
            },
            input: {
              margin: 0,
              padding: 0,
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              color: "#FFFFFF",
            },
            suggestions: {
              backgroundColor: null,
              list: {
                backgroundColor: "rgb(43,46,50)",
                border: "1px solid rgba(0,0,0,0.15)",
                fontSize: 14,
                borderRadius: "5px",
              },
              item: {
                padding: "5px 15px",
                zIndex: "1000!important",
                borderBottom: "1px solid rgba(0,0,0,0.15)",
                backgroundColor: "rgb(43,46,50)",
                "&focused": {
                  backgroundColor: "#525860",
                },
              },
            },
          }}
          placeholder="Type your message here. Use / to mention files from the selected project and # to mention files from the library. Use @ to mention Eureka database."
          disabled={isProcessing}
        >
          <Mention
            trigger="/"
            data={projectFiles
              .filter((f) => !f.is_processing)
              .map((file) => ({ id: file.name, display: file.name }))}
            style={{
              backgroundColor: "rgba(134, 188, 37, 0.5)",
              paddingTop: 3,
              paddingBottom: 3,
              borderRadius: 4,
            }}
            displayTransform={(_, display) => `@📄${display}`}
          />
          <Mention
            trigger="#"
            data={libraryFiles
              .filter((f) => !f.is_processing)
              .map((file) => ({ id: file.name, display: file.name }))}
            style={{
              backgroundColor: "rgba(134, 188, 37, 0.5)",
              paddingTop: 3,
              paddingBottom: 3,
              borderRadius: 4,
            }}
            displayTransform={(_, display) => `@🌝${display}`}
          />
          <Mention
            trigger="@"
            data={[{ id: "eureka.mf.gov.pl", display: "eureka.mf.gov.pl" }]}
            style={{
              backgroundColor: "rgba(134, 188, 37, 0.5)",
              paddingTop: 3,
              paddingBottom: 3,
              borderRadius: 4,
            }}
            displayTransform={(_, display) => `@🌝${display}`}
          />
        </MentionsInput>
      </div>
      <Button
        type="primary"
        icon={isProcessing ? <StopOutlined /> : <SendOutlined />}
        onClick={isProcessing ? onStop : onSend}
        disabled={!value.trim() && !isProcessing}
        style={{
          position: "absolute",
          right: "8px",
          top: "50%",
          transform: "translateY(-50%)",
          border: "none",
          background: "none",
          color: isProcessing ? "#ff4d4f" : "#86bc25",
        }}
      />
    </div>
  );
};
const SearchPage: React.FC<{}> = () => {
  const proChatRef = useRef<ProChatInstance>();

  const mainContext = useContext(MainContext);
  const filesContext = useContext(FilesContext);
  const [isAutocompleteVisible, setAutocompleteVisible] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const project = mainContext ? mainContext.projectSelected : "";
  const setProject = mainContext?.setSearchProjectSelected;
  const projects = mainContext?.projects;
  // console.log(project);
  const handleInputChange = (e) => {
    const value = e;
    //console.log(isAutocompleteVisible);
    setInputValue(value);

    const lastChar = value.charAt(value.length - 1);
    const hasSlash = value.includes("/");

    if (lastChar === "/" || (hasSlash && !isAutocompleteVisible)) {
      //console.log('autocomplete');
      setAutocompleteVisible(true);
    } else if (!hasSlash) {
      setAutocompleteVisible(false);
    }
  };

  const handleProChatKeyDown = (e) => {
    if (e.key === "Enter" && isAutocompleteVisible === true) {
      //console.log('NONE');
      e.preventDefault();
    }
  };
  const handleKeyDown = (e) => {
    //console.log(proChatRef.current.getChatLoadingId());
    const cursorPos = e.target.selectionStart;
    const beforeCursor = inputValue.slice(0, cursorPos);
    const afterCursor = inputValue.slice(cursorPos);

    const fileTagPattern = /\[(?:📄|🌝) [^\]]+\]/g;
    const matches = [...inputValue.matchAll(fileTagPattern)];

    // Find the tag that the cursor is within or next to
    let matchToRemove = null;
    matches.forEach((match) => {
      const matchStart = match.index;
      const matchEnd = match.index + match[0].length;
      if (
        (cursorPos > matchStart && cursorPos <= matchEnd) ||
        cursorPos === matchEnd
      ) {
        matchToRemove = match;
      }
    });

    // Prevent editing inside the tag
    if (matchToRemove) {
      const matchStart = matchToRemove.index;
      const matchEnd = matchToRemove.index + matchToRemove[0].length;

      if (
        ["Backspace", "Delete"].includes(e.key) &&
        cursorPos > matchStart &&
        cursorPos <= matchEnd
      ) {
        // Allow backspace and delete only at the tag boundary
        const newValue =
          inputValue.slice(0, matchToRemove.index) +
          inputValue.slice(matchToRemove.index + matchToRemove[0].length);
        setInputValue(newValue);
        e.preventDefault();
        return;
      }

      if (
        cursorPos > matchStart &&
        cursorPos < matchEnd &&
        !["ArrowLeft", "ArrowRight"].includes(e.key)
      ) {
        // Prevent other keys except arrow keys within the tag
        e.preventDefault();
        return;
      }
    }

    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      isAutocompleteVisible === false &&
      typeof proChatRef.current.getChatLoadingId() !== "string"
    ) {
      e.preventDefault();

      const message = inputValue;

      proChatRef.current.sendMessage(message);
      setInputValue("");
    }
  };
  function updateChats() {
    const chatMessages = document.querySelectorAll(
      ".ant-pro-chat-list-item-message-content a"
    );

    chatMessages.forEach(async (link) => {
      link.style.color = "#86bc25";
      const originalHref = link.getAttribute("href");
      if (originalHref && originalHref.includes("eureka")) {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      }
      if (originalHref == "#" || originalHref.includes("eureka")) {
        return;
      }
      // Modify the href attribute
      link.setAttribute("href", "#"); // or modify based on some logic
      var filename = originalHref.replace(
        "https://documentor.blob.core.windows.net/media/",
        ""
      );
      link.textContent = filename;
      // Add a custom click handler

      if (
        originalHref &&
        originalHref.includes("https://documentor.blob.core.windows.net/media/")
      ) {
        link.onclick = async (event) => {
          event.preventDefault(); // Prevent the default link behavior
          mainContext?.setTextViewContent({
            text: undefined,
          });
          mainContext?.setIsTextViewVisible(true);
          let textResponse = await mainContext?.get_doc_text(
            filename,
            undefined
          );

          mainContext?.setTextViewContent({
            text: textResponse["text"],
            title: filename,
          });
        };
      }
    });
  }

  const inputAreaRender = useCallback(
    (
      _: ReactNode,
      onMessageSend: (message: string) => void | Promise<any>,
      onClear: () => void
    ) => {
      const handleSend = async () => {
        if (inputValue.trim() && !isProcessing) {
          setIsProcessing(true);
          // proChatRef.current.sendMessage(inputValue);
          await onMessageSend(inputValue);
          setInputValue("");
          // setIsProcessing(false);
        }
      };

      const handleStop = () => {
        if (proChatRef.current) {
          proChatRef.current.stopGenerateMessage();
        }
        console.log("stop");
        setIsProcessing(false);
      };

      return (
        <div>
          <CustomInput
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onSend={handleSend}
            onStop={handleStop}
            isProcessing={isProcessing}
            projectFiles={filesContext?.projectFiles || []}
            libraryFiles={filesContext?.libraryFiles || []}
          />
          <div className={styles.bottom_buttons_container}>
            <Button onClick={onClear} icon={<ClearOutlined />}>
              Clear Chat
            </Button>
            <AIAgentFeedback />
          </div>
        </div>
      );
    },
    [inputValue, isProcessing, filesContext?.projectFiles]
  );
  const getLibraryFiles = async () => {
    console.log("teamSelected", mainContext?.teamSelected);
    if (!mainContext?.teamSelected?.id) return [];

    try {
      const libraryProjects =
        mainContext?.projects.filter((project) => project.is_library_project) ||
        [];

      // Add logging for each project fetch
      const filesPromises = libraryProjects.map(async (project) => {
        const files = await FilesService.fetchFiles(project.id);
        return files;
      });

      // Wait for all promises to resolve
      const filesArrays = await Promise.all(filesPromises);

      // Check if any of the arrays are undefined or empty
      const flattenedFiles = filesArrays
        .filter((array) => array && array.length > 0)
        .flat();

      return flattenedFiles;
    } catch (error) {
      console.error("Error fetching library files:", error);
      return [];
    }
  };

  useEffect(() => {
    getLibraryFiles().then((files) => {
      filesContext?.setLibraryFiles(files);
    });
  }, [mainContext?.projects]);
  return (
    <div className={styles.content}>
      <Tabs
        className={styles.tools_tab}
        type="line"
        size="large"
        centered
        activeKey={mainContext?.searchMenuItemsSelected}
        onChange={(activeKey) => {
          mainContext?.setSearchMenuItemsSelected(
            activeKey as "search" | "chat"
          );
          setTimeout(() => {
            updateChats();
          }, 2000);
        }}
        items={[
          {
            label: "AI Agent",
            key: "chat",

            icon: (
              <BsStars style={{ marginBottom: 2, width: 18, height: 18 }} />
            ),
          },
          {
            label: "Search",
            key: "search",
            icon: (
              <FaSearch style={{ marginBottom: 2, width: 18, height: 18 }} />
            ),
          },
        ]}
      />
      <>
        <div style={{ height: 10 }} />

        {mainContext?.searchMenuItemsSelected === "search" ? (
          <>
            <SearchInterface />{" "}
          </>
        ) : undefined}
        {mainContext?.searchMenuItemsSelected === "chat" ? (
          <>
            {/* @ts-ignore */}
            <ThemeProvider
              appearance="dark"
              theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                  colorPrimary: "#26890d",
                  colorText: "#FFFFFF",
                },
              }}
            >
              <ProChat
                helloMessage={initMessage}
                className={CustomClassName}
                chatRef={proChatRef}
                inputAreaRender={inputAreaRender}
                onChatEnd={(id, type) => {
                  console.log("chat end", id);
                  console.log("chat end type", type);
                }}
                chats={mainContext?.chatMessages || []}
                onChatsChange={async (chats) => {
                  await updateChats();
                  mainContext?.setChatMessages(chats);
                  console.log("chat messages");
                  setTimeout(() => {
                    updateChats();
                  }, 2000);
                }}
                onChatsGenerate={() => {
                  console.log("chat generate");
                }}
                userMeta={{ avatar: "🧑”🎓", title: "User" }}
                locale={"en-US"}
                chatItemRenderConfig={{
                  actionsRender: () => <></>,
                }}
                request={async function (
                  messages: Array<any>,
                  payload,
                  signal
                ) {
                  setIsProcessing(true);
                  return mainContext.handleChatRequest(
                    messages,
                    project,
                    () => {
                      setIsProcessing(false);
                    }
                  );
                }}
              />
            </ThemeProvider>
          </>
        ) : undefined}
      </>
    </div>
  );
};

export default SearchPage;
