import React, { useContext, useState } from "react";
import { Button, List, Modal } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { UploadedFile } from "../../../../../../interfaces/uploadedFile.interface";
import getCookie from "../../../../../../helpers/getCookie";
import MainContext from "../../../../../../contexts/MainContext";
import FilesContext from "../../../../../../contexts/FilesContext";
import * as FilesService from "../../../../services/files.service";

import styles from "./DeleteFilesModal.module.css";

interface DeleteFilesModalProps {
  isOpen: boolean;
  onClose: () => void;
  files: UploadedFile[];
}

const DeleteFilesModal: React.FC<DeleteFilesModalProps> = ({
  isOpen,
  onClose,
  files,
}) => {
  const mainContext = useContext(MainContext);
  const filesContext = useContext(FilesContext);

  const [isLoading, setIsLoading] = useState(false);

  const csrfToken = getCookie("csrftoken") ?? "";

  const onSubmit = async () => {
    setIsLoading(true);
    await FilesService.deleteFiles(
      csrfToken,
      files.map((file) => file.id)
    );

    if (mainContext?.projectSelected) {
      filesContext?.getProjectFiles(mainContext.projectSelected.id);

      filesContext?.setAllTabSelectedFiles([]);
      // TODO: reset selected files array for every tab state
    }

    setIsLoading(false);

    onClose();
  };

  return (
    <Modal
      width="80%"
      centered
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          Are you sure you want to continue?
        </p>,
        <div key="subTitle" className={styles.subTitle}>
          <p className={styles.warning}>
            <WarningOutlined size={20} /> Warning
          </p>
          <p>
            You are about to delete files that might currently be in use by a
            tool. Deleting these files could lead to unexpected results. Before
            you continue, please ensure that no active tool processes are using
            these files.
          </p>
        </div>,
      ]}
      onOk={onSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" className={styles.close_button} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          loading={isLoading}
          onClick={onSubmit}
        >
          Ok
        </Button>,
      ]}
    >
      <p className={styles.deletion_list_title}>Files to delete</p>
      <List
        className={styles.deletion_list}
        size="small"
        bordered
        dataSource={files.map((file) => file.name)}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Modal>
  );
};

export default DeleteFilesModal;
