import React, { useContext, useState } from "react";
import FilesContext from "../../../../contexts/FilesContext";
import { CloseOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Space } from "antd";
import type { MenuProps } from "antd";
import { BiSortAZ, BiSortAlt2, BiSortZA } from "react-icons/bi";
import { BsStars } from "react-icons/bs";
import { MdOutlineBackHand } from "react-icons/md";
import { SortOrders } from "../../enums/sortOrders.enum";
import ConfirmAIConnectionModal from "../AllTab/components/ConfirmAIConnectionModal/ConfirmAIConnectionModal";
import { FilesPageTabs } from "../../../../enums/filesPageTabs.enum";

import styles from "./ActionHeader.module.css";

const sortingIcons = {
  [SortOrders.Default]: <BiSortAlt2 className={styles.sort_icon} />,
  [SortOrders.Ascending]: <BiSortAZ className={styles.sort_icon} />,
  [SortOrders.Descending]: <BiSortZA className={styles.sort_icon} />,
};

const sortingLabels = {
  [SortOrders.Default]: "Sort by",
  [SortOrders.Ascending]: "Name (A-Z)",
  [SortOrders.Descending]: "Name (Z-A)",
};

interface ActionHeaderProps {
  sortOrder: SortOrders;
  setSortOrder: (order: SortOrders) => void;
  searchText: string;
  setSearchText: (data: string) => void;
}

const ActionHeader: React.FC<ActionHeaderProps> = ({
  sortOrder,
  setSortOrder,
  searchText,
  setSearchText,
}) => {
  const filesContext = useContext(FilesContext);

  const [isAIConfirmationOpen, setIsAIConfirmationOpen] = useState(false);

  const sortingItems: MenuProps["items"] = [
    {
      label: "Name (A-Z)",
      key: "asc",
      icon: sortingIcons[SortOrders.Ascending],
    },
    {
      label: "Name (Z-A)",
      key: "desc",
      icon: sortingIcons[SortOrders.Descending],
    },
  ];

  const handleSortClick: MenuProps["onClick"] = (e) => {
    setSortOrder(e.key as SortOrders);
  };

  const sortingProps = {
    items: sortingItems,
    onClick: handleSortClick,
  };

  return (
    <>
      <div className={styles.header_container}>
        <div className={styles.actions_container}>
          <Dropdown
            menu={sortingProps}
            trigger={["click"]}
            disabled={filesContext?.projectFiles.length === 0}
          >
            <Button>
              <Space>
                {sortingIcons[sortOrder]}
                {sortingLabels[sortOrder]}
                {sortOrder === SortOrders.Default ? (
                  <DownOutlined />
                ) : (
                  <CloseOutlined
                    onClick={(event) => {
                      event.stopPropagation();
                      setSortOrder(SortOrders.Default);
                    }}
                  />
                )}
              </Space>
            </Button>
          </Dropdown>

          <Input
            className={styles.search_input}
            disabled={filesContext?.projectFiles.length === 0}
            value={searchText}
            placeholder="Search files"
            prefix={<SearchOutlined className={styles.search_icon} />}
            allowClear
            size="middle"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        {filesContext?.filesPageTabSelected === FilesPageTabs.All && (
          <div className={styles.actions_container}>
            <Button
              disabled={filesContext?.allTabSelectedFiles.length! < 2}
              icon={<MdOutlineBackHand />}
            >
              Connect manually
            </Button>
            <Button
              disabled={filesContext?.allTabSelectedFiles.length! < 2}
              type="primary"
              icon={<BsStars />}
              onClick={() => setIsAIConfirmationOpen(true)}
            >
              Connect with AI
            </Button>
          </div>
        )}
      </div>

      <ConfirmAIConnectionModal
        isOpen={isAIConfirmationOpen}
        setIsOpen={setIsAIConfirmationOpen}
      />
    </>
  );
};

export default ActionHeader;
