import React, { useContext, useEffect, useState } from "react";
import EmptyState from "./components/EmptyState/EmptyState";
import { Button, Checkbox, Collapse, Divider } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import FilesContext from "../../../../contexts/FilesContext";

import ActionHeader from "../ActionHeader/ActionHeader";
import { SortOrders } from "../../enums/sortOrders.enum";

import styles from "./ForApprovalTab.module.css";

const mockIncome = [
  {
    name: "22/02/2024 8:40",
    connections: [
      [
        { id: 1, name: "File1.pdf", main: true },
        { id: 2, name: "File2.pdf", main: false },
        { id: 3, name: "File3.pdf", main: false },
        { id: 4, name: "File4.pdf", main: false },
      ],
      [
        { id: 5, name: "File5.pdf", main: true },
        { id: 6, name: "File6.pdf", main: false },
        { id: 7, name: "File7.pdf", main: false },
      ],
      [
        { id: 8, name: "File8.pdf", main: true },
        { id: 9, name: "File9.pdf", main: false },
        { id: 10, name: "File10.pdf", main: false },
      ],
    ],
  },
  {
    name: "13/02/2024 11:20",
    connections: [
      [
        { id: 11, name: "File11.pdf", main: true },
        { id: 12, name: "File12.pdf", main: false },
        { id: 13, name: "File13.pdf", main: false },
        { id: 14, name: "File14.pdf", main: false },
        { id: 15, name: "File15.pdf", main: false },
      ],
      [
        { id: 16, name: "File16.pdf", main: true },
        { id: 17, name: "File17.pdf", main: false },
        { id: 18, name: "File18.pdf", main: false },
        { id: 19, name: "File19pdf", main: false },
      ],
    ],
  },
];

const ForApprovalTab: React.FC = () => {
  const filesContext = useContext(FilesContext);
  const [mockData, setMockData] = useState<
    {
      key: number;
      label: JSX.Element;
      children: JSX.Element;
      style: {
        marginBottom: number;
        background: string;
        borderRadius: number;
        border: string;
      };
    }[]
  >([]);

  const [sortOrder, setSortOrder] = useState<SortOrders>(SortOrders.Default);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const data = mockIncome.map((item, index) => {
      const mappedConnections = item.connections.map(
        (connection, connectionIndex) => {
          const mappedConnection = {
            key: connectionIndex,
            label: (
              <Checkbox
                className={styles.parent_file}
                onChange={(e) => console.log(e.target)}
              >
                {connection.find((file) => file.main)?.name}
              </Checkbox>
            ),
            children: (
              <>
                {connection
                  .filter((file) => !file.main)
                  .map((file) => {
                    return (
                      <p key={file.id} className={styles.child_file}>
                        <Checkbox onChange={(e) => console.log(e.target)}>
                          {file.name}
                        </Checkbox>
                      </p>
                    );
                  })}
              </>
            ),
            style: {
              marginBottom: 16,
              borderRadius: 4,
              border: "1px solid #c7c8c9",
            },
          };

          return mappedConnection;
        }
      );

      const mappedItem = {
        key: index,
        label: <p className={styles.group_name}>{item.name}</p>,
        children: (
          <div>
            <p>
              You have 2 connections established. There were no connections
              found for 1 folder and 2 files.
            </p>
            <div className={styles.approve_button_container}>
              <Button
                key="approve"
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => console.log("APPROVE")}
              >
                Approve
              </Button>
            </div>
            <Divider />
            <Collapse
              size="small"
              collapsible="icon"
              bordered={false}
              defaultActiveKey={0}
              expandIconPosition="end"
              style={{ background: "transparent" }}
              items={mappedConnections}
            />
          </div>
        ),
        style: {
          marginBottom: 24,
          background: "#262730",
          borderRadius: 4,
          border: "none",
        },
      };
      return mappedItem;
    });
    setMockData(data);
  }, []);

  // const onSelect = (
  //   file: UploadedFile,
  //   isChecked: boolean,
  // ) => {
  //   filesContext?.setForApprovalTabSelectedFiles((prev) =>
  //     isChecked ? [...prev, file] : prev.filter(({ id }) => id !== file.id)
  //   );
  // };

  return (
    <div className={styles.approval_container}>
      {/* <ActionHeader
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <h5>New connections for approval</h5>

      <div className={styles.for_approve_connections_container}>
        <Collapse
          bordered={false}
          defaultActiveKey={0}
          expandIconPosition="end"
          style={{ background: "transparent" }}
          items={mockData}
        />
      </div> */}

      <EmptyState />
    </div>
  );
};

export default ForApprovalTab;
