import React, {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";

import MainContext from "./MainContext";
import {
  MetadataTemplate,
  ObligationTemplate,
  RiskTemplate,
  ComparisonPlaybookTemplate,
  ComparisonRegulationCustomTemplate,
  ComparisonRegulationPredefinedTemplate,
  ComparisonContractTemplate,
  ComparisonRedliningTemplate,
  ComparisonChecklistCustomTemplate,
  ComparisonChecklistPredefinedTemplate,
  SummarizationTemplate,
} from "../data/Template";
import getCookie from "../helpers/getCookie";
import readBase64 from "../helpers/readBase64";
import { SummarizationTypes } from "../pages/ToolsPage/components/DocumentSummarization/helpers/summarizationTypes.enum";

interface TemplatesContextType {
  fetchAllMetadata: () => Promise<Array<{ id: string | number; name: string }>>;
  fetchMetadata: (id: string | number) => Promise<MetadataTemplate>;
  deleteMetadata: (id: string | number) => Promise<void>;
  editMetadata: (
    id: string | number,
    body: {
      name: string;
      metadata: Array<number | string>;
      custom_metadata: Array<{
        metadata_parameter: string;
        description: string;
        metadata_type: string;
        output_format: string;
      }>;
      language: string;
    }
  ) => Promise<void>;
  createMetadata: (body: {
    name: string;
    metadata: Array<number | string>;
    custom_metadata: Array<{
      metadata_parameter: string;
      description: string;
      metadata_type: string;
      output_format: string;
    }>;
    language: string;
  }) => Promise<string | number>;
  fetchAllObligations: () => Promise<
    Array<{ id: string | number; name: string }>
  >;
  fetchObligation: (id: string | number) => Promise<ObligationTemplate>;
  deleteObligation: (id: string | number) => Promise<void>;
  editObligation: (
    id: string | number,
    body: {
      name: string;
      obligations: Array<number | string>;
      language: string;
    }
  ) => Promise<void>;
  createObligation: (body: {
    name: string;
    obligations: Array<number | string>;
    language: string;
  }) => Promise<string | number>;
  fetchAllRisks: () => Promise<Array<{ id: string | number; name: string }>>;
  fetchRisk: (id: string | number) => Promise<RiskTemplate>;
  deleteRisk: (id: string | number) => Promise<void>;
  editRisk: (
    id: string | number,
    body: { name: string; risks: Array<number | string>; language: string }
  ) => Promise<void>;
  createRisk: (body: {
    name: string;
    risks: Array<number | string>;
    language: string;
  }) => Promise<string | number>;
  fetchAllSummarizations: () => Promise<
    Array<{ id: string | number; name: string }>
  >;
  fetchSummarization: (id: string | number) => Promise<SummarizationTemplate>;
  deleteSummarization: (id: string | number) => Promise<void>;
  editSummarization: (
    id: string | number,
    body: Omit<SummarizationTemplate, "id" | "team">
  ) => Promise<void>;
  createSummarization: (
    body: Omit<SummarizationTemplate, "id" | "team">
  ) => Promise<string | number>;
  fetchAllComparison: () => Promise<
    Array<{
      id: string | number;
      name: string;
      unique_id: string | number;
      comparison_type: string;
    }>
  >;
  fetchComparisonPlaybook: (
    id: string | number
  ) => Promise<ComparisonPlaybookTemplate>;
  deleteComparisonPlaybook: (id: string | number) => Promise<void>;
  editComparisonPlaybook: (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => Promise<void>;
  createComparisonPlaybook: (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => Promise<string | number>;
  fetchComparisonRegulationCustom: (
    id: string | number
  ) => Promise<ComparisonRegulationCustomTemplate>;
  deleteComparisonRegulationCustom: (id: string | number) => Promise<void>;
  editComparisonRegulationCustom: (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => Promise<void>;
  createComparisonRegulationCustom: (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => Promise<string | number>;
  fetchComparisonRegulationPredefined: (
    id: string | number
  ) => Promise<ComparisonRegulationPredefinedTemplate>;
  deleteComparisonRegulationPredefined: (id: string | number) => Promise<void>;
  editComparisonRegulationPredefined: (
    id: string | number,
    body: {
      name: string;
      file: string | number;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => Promise<void>;
  createComparisonRegulationPredefined: (body: {
    name: string;
    file: string | number;
    specific_clauses_to_check: string;
    language: string;
  }) => Promise<string | number>;
  fetchComparisonContract: (
    id: string | number
  ) => Promise<ComparisonContractTemplate>;
  deleteComparisonContract: (id: string | number) => Promise<void>;
  editComparisonContract: (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => Promise<void>;
  createComparisonContract: (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => Promise<string | number>;
  fetchComparisonRedlining: (
    id: string | number
  ) => Promise<ComparisonRedliningTemplate>;
  deleteComparisonRedlining: (id: string | number) => Promise<void>;
  editComparisonRedlining: (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => Promise<void>;
  createComparisonRedlining: (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => Promise<string | number>;
  fetchComparisonChecklistCustom: (
    id: string | number
  ) => Promise<ComparisonChecklistCustomTemplate>;
  deleteComparisonChecklistCustom: (id: string | number) => Promise<void>;
  editComparisonChecklistCustom: (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => Promise<void>;
  createComparisonChecklistCustom: (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => Promise<string | number>;
  fetchComparisonChecklistPredefined: (
    id: string | number
  ) => Promise<ComparisonChecklistPredefinedTemplate>;
  deleteComparisonChecklistPredefined: (id: string | number) => Promise<void>;
  editComparisonChecklistPredefined: (
    id: string | number,
    body: {
      name: string;
      file: string | number;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => Promise<void>;
  createComparisonChecklistPredefined: (body: {
    name: string;
    file: string | number;
    specific_clauses_to_check: string;
    language: string;
  }) => Promise<string | number>;
}

interface TemplatesProviderProps {
  children: ReactNode;
}

const TemplatesContext = createContext<TemplatesContextType | undefined>(
  undefined
);

export const TemplatesProvider: React.FC<TemplatesProviderProps> = (props) => {
  const mainContext = useContext(MainContext);
  const backendUrl = process.env.REACT_APP_API_URL ?? "";
  const csrfToken = getCookie("csrftoken");

  const fetchAllMetadata = async () => {
    const response = await fetch(
      `${backendUrl}/api/metadata_templates/${mainContext?.teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: Array<{ id: string | number; name: string }> =
      await response.json();

    return body;
  };

  const fetchMetadata = async (id: string | number) => {
    const response = await fetch(`${backendUrl}/api/metadata_template/${id}`, {
      method: "GET",
      credentials: "include",
    });
    const body: {
      id: number | string;
      contract_type: { id: string | number; name: string } | undefined;
      name: string;
      metadata: Array<{
        id: number | string;
        name: string;
        description: string;
        type: string;
        output_format: string;
      }>;
      custom_metadata: Array<{
        id: number | string;
        metadata_parameter: string;
        description: string;
        metadata_type: string;
        output_format: string;
      }>;
      language: string;
    } = await response.json();

    return new MetadataTemplate(
      body.id,
      body.name,
      body.contract_type,
      body.metadata,
      body.custom_metadata,
      body.language
    );
  };

  const deleteMetadata = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_metadata_template/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const editMetadata = async (
    id: string | number,
    body: {
      name: string;
      metadata: Array<number | string>;
      custom_metadata: Array<{
        metadata_parameter: string;
        description: string;
        metadata_type: string;
        output_format: string;
      }>;
      language: string;
    }
  ) => {
    await fetch(`${backendUrl}/api/edit_metadata_template/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ ...body, team: mainContext?.teamSelected?.id }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      credentials: "include",
    });
  };

  const createMetadata = async (body: {
    name: string;
    metadata: Array<number | string>;
    custom_metadata: Array<{
      metadata_parameter: string;
      description: string;
      metadata_type: string;
      output_format: string;
    }>;
    language: string;
  }) => {
    const response = await fetch(
      `${backendUrl}/api/create_metadata_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchAllObligations = async () => {
    const response = await fetch(
      `${backendUrl}/api/obligation_templates/${mainContext?.teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: Array<{ id: string | number; name: string }> =
      await response.json();

    return body;
  };

  const fetchObligation = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/obligation_template/${id}`,
      { method: "GET", credentials: "include" }
    );
    const body: {
      id: number | string;
      name: string;
      contract_type: { id: string | number; name: string } | undefined;
      obligations: Array<{
        id: number | string;
        name: string;
        description: string;
      }>;
      language: string;
    } = await response.json();

    return new ObligationTemplate(
      body.id,
      body.name,
      body.contract_type,
      body.obligations,
      [],
      body.language
    );
  };

  const deleteObligation = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_obligation_template/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const editObligation = async (
    id: string | number,
    body: {
      name: string;
      obligations: Array<number | string>;
      language: string;
    }
  ) => {
    await fetch(`${backendUrl}/api/edit_obligation_template/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        ...body,
        customObligations: [],
        team: mainContext?.teamSelected?.id,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      credentials: "include",
    });
  };

  const createObligation = async (body: {
    name: string;
    obligations: Array<number | string>;
    language: string;
  }) => {
    const response = await fetch(
      `${backendUrl}/api/create_obligation_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...body, customObligations: [] }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchAllRisks = async () => {
    const response = await fetch(
      `${backendUrl}/api/risk_templates/${mainContext?.teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: Array<{ id: string | number; name: string }> =
      await response.json();

    return body;
  };

  const fetchRisk = async (id: string | number) => {
    const response = await fetch(`${backendUrl}/api/risk_template/${id}`, {
      method: "GET",
      credentials: "include",
    });
    const body: {
      id: number | string;
      name: string;
      contract_type: { id: string | number; name: string } | undefined;
      risks: Array<{ id: number | string; name: string; description: string }>;
      language: string;
    } = await response.json();

    return new RiskTemplate(
      body.id,
      body.name,
      body.contract_type,
      body.risks,
      [],
      body.language
    );
  };

  const deleteRisk = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_risk_template/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const editRisk = async (
    id: string | number,
    body: { name: string; risks: Array<number | string>; language: string }
  ) => {
    await fetch(`${backendUrl}/api/edit_risk_template/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        ...body,
        customRisks: [],
        team: mainContext?.teamSelected?.id,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      credentials: "include",
    });
  };

  const createRisk = async (body: {
    name: string;
    risks: Array<number | string>;
    language: string;
  }) => {
    const response = await fetch(
      `${backendUrl}/api/create_risk_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...body, customRisks: [] }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchAllSummarizations = async () => {
    const response = await fetch(
      `${backendUrl}/api/summarization_templates/${mainContext?.teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: Array<{ id: string | number; name: string }> =
      await response.json();

    return body;
  };

  const fetchSummarization = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/summarization_template/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const body: {
      id: string | number;
      name: string;
      team: string | number;
      type: SummarizationTypes;
      length_of_summary: string;
      custom_prompt: string;
      output_format: string;
      first_language: string;
      second_language: string;
    } = await response.json();

    return new SummarizationTemplate(
      body.id,
      body.name,
      body.team,
      body.type,
      body.length_of_summary,
      body.custom_prompt,
      body.output_format,
      body.first_language,
      body.second_language
    );
  };

  const deleteSummarization = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_summarization_template/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const editSummarization = async (
    id: string | number,
    body: Omit<SummarizationTemplate, "id" | "team">
  ) => {
    await fetch(`${backendUrl}/api/edit_summarization_template/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        ...body,
        team: mainContext?.teamSelected?.id,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      credentials: "include",
    });
  };

  const createSummarization = async (
    body: Omit<SummarizationTemplate, "id" | "team">
  ) => {
    const response = await fetch(
      `${backendUrl}/api/create_summarization_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...body, team: mainContext?.teamSelected?.id }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchAllComparison = async () => {
    const response = await fetch(
      `${backendUrl}/api/comparison_templates/${mainContext?.teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: Array<{
      id: string | number;
      name: string;
      unique_id: string | number;
      comparison_type: string;
    }> = await response.json();

    return body;
  };

  const fetchComparisonPlaybook = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_playbook_template/${id}`,
      { method: "GET", credentials: "include" }
    );
    const body: {
      id: number | string;
      name: string;
      file: { base64_url: string; filename: string };
      specific_clauses_to_check: string;
      language: string;
    } = await response.json();

    const file = await readBase64(body.file.base64_url, body.file.filename);

    return new ComparisonPlaybookTemplate(
      body.id,
      body.name,
      file,
      body.specific_clauses_to_check,
      body.language
    );
  };

  const deleteComparisonPlaybook = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_comparison_playbook_template/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const editComparisonPlaybook = async (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => {
    const formData = new FormData();
    formData.append("team", mainContext?.teamSelected?.id.toString()!);
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    await fetch(`${backendUrl}/api/edit_comparison_playbook_template/${id}`, {
      method: "PATCH",
      body: formData,
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const createComparisonPlaybook = async (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => {
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    const response = await fetch(
      `${backendUrl}/api/create_comparison_playbook_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchComparisonRegulationCustom = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_regulation_custom_template/${id}`,
      { method: "GET", credentials: "include" }
    );
    const body: {
      id: number | string;
      name: string;
      file: { base64_url: string; filename: string };
      specific_clauses_to_check: string;
      language: string;
    } = await response.json();

    const file = await readBase64(body.file.base64_url, body.file.filename);

    return new ComparisonRegulationCustomTemplate(
      body.id,
      body.name,
      file,
      body.specific_clauses_to_check,
      body.language
    );
  };

  const deleteComparisonRegulationCustom = async (id: string | number) => {
    await fetch(
      `${backendUrl}/api/delete_comparison_regulation_custom_template/${id}`,
      {
        method: "DELETE",
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
  };

  const editComparisonRegulationCustom = async (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => {
    const formData = new FormData();
    formData.append("team", mainContext?.teamSelected?.id.toString()!);
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    await fetch(
      `${backendUrl}/api/edit_comparison_regulation_custom_template/${id}`,
      {
        method: "PATCH",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
  };

  const createComparisonRegulationCustom = async (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => {
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    const response = await fetch(
      `${backendUrl}/api/create_comparison_regulation_custom_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchComparisonRegulationPredefined = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_regulation_predefined_template/${id}`,
      { method: "GET", credentials: "include" }
    );
    const body: {
      id: number | string;
      name: string;
      file: { id: number; name: string };
      specific_clauses_to_check: string;
      language: string;
    } = await response.json();

    return new ComparisonRegulationPredefinedTemplate(
      body.id,
      body.name,
      body.file,
      body.specific_clauses_to_check,
      body.language
    );
  };

  const deleteComparisonRegulationPredefined = async (id: string | number) => {
    await fetch(
      `${backendUrl}/api/delete_comparison_regulation_predefined_template/${id}`,
      {
        method: "DELETE",
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
  };

  const editComparisonRegulationPredefined = async (
    id: string | number,
    body: {
      name: string;
      file: string | number;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => {
    const formData = new FormData();
    formData.append("team", mainContext?.teamSelected?.id.toString()!);
    formData.append("name", body.name);
    formData.append("file", body.file.toString());
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    await fetch(
      `${backendUrl}/api/edit_comparison_regulation_predefined_template/${id}`,
      {
        method: "PATCH",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
  };

  const createComparisonRegulationPredefined = async (body: {
    name: string;
    file: string | number;
    specific_clauses_to_check: string;
    language: string;
  }) => {
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("file", body.file.toString());
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    const response = await fetch(
      `${backendUrl}/api/create_comparison_regulation_predefined_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchComparisonContract = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_contract_template/${id}`,
      { method: "GET", credentials: "include" }
    );
    const body: {
      id: number | string;
      name: string;
      file: { base64_url: string; filename: string };
      specific_clauses_to_check: string;
      language: string;
    } = await response.json();

    const file = await readBase64(body.file.base64_url, body.file.filename);

    return new ComparisonContractTemplate(
      body.id,
      body.name,
      file,
      body.specific_clauses_to_check,
      body.language
    );
  };

  const deleteComparisonContract = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_comparison_contract_template/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const editComparisonContract = async (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => {
    const formData = new FormData();
    formData.append("team", mainContext?.teamSelected?.id.toString()!);
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    await fetch(`${backendUrl}/api/edit_comparison_contract_template/${id}`, {
      method: "PATCH",
      body: formData,
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const createComparisonContract = async (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => {
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    const response = await fetch(
      `${backendUrl}/api/create_comparison_contract_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchComparisonRedlining = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_redlining_template/${id}`,
      { method: "GET", credentials: "include" }
    );
    const body: {
      id: number | string;
      name: string;
      file: { base64_url: string; filename: string };
      specific_clauses_to_check: string;
      language: string;
    } = await response.json();

    const file = await readBase64(body.file.base64_url, body.file.filename);

    return new ComparisonRedliningTemplate(
      body.id,
      body.name,
      file,
      body.specific_clauses_to_check,
      body.language
    );
  };

  const deleteComparisonRedlining = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_comparison_redlining_template/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const editComparisonRedlining = async (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => {
    const formData = new FormData();
    formData.append("team", mainContext?.teamSelected?.id.toString()!);
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    await fetch(`${backendUrl}/api/edit_comparison_redlining_template/${id}`, {
      method: "PATCH",
      body: formData,
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });
  };

  const createComparisonRedlining = async (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => {
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    const response = await fetch(
      `${backendUrl}/api/create_comparison_redlining_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchComparisonChecklistCustom = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_checklist_custom_template/${id}`,
      { method: "GET", credentials: "include" }
    );
    const body: {
      id: number | string;
      name: string;
      file: { base64_url: string; filename: string };
      specific_clauses_to_check: string;
      language: string;
    } = await response.json();

    const file = await readBase64(body.file.base64_url, body.file.filename);

    return new ComparisonChecklistCustomTemplate(
      body.id,
      body.name,
      file,
      body.specific_clauses_to_check,
      body.language
    );
  };

  const deleteComparisonChecklistCustom = async (id: string | number) => {
    await fetch(
      `${backendUrl}/api/delete_comparison_checklist_custom_template/${id}`,
      {
        method: "DELETE",
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
  };

  const editComparisonChecklistCustom = async (
    id: string | number,
    body: {
      name: string;
      file: File;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => {
    const formData = new FormData();
    formData.append("team", mainContext?.teamSelected?.id.toString()!);
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    await fetch(
      `${backendUrl}/api/edit_comparison_checklist_custom_template/${id}`,
      {
        method: "PATCH",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
  };

  const createComparisonChecklistCustom = async (body: {
    name: string;
    file: File;
    specific_clauses_to_check: string;
    language: string;
  }) => {
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("file", body.file);
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    const response = await fetch(
      `${backendUrl}/api/create_comparison_checklist_custom_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  const fetchComparisonChecklistPredefined = async (id: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_checklist_predefined_template/${id}`,
      { method: "GET", credentials: "include" }
    );
    const body: {
      id: number | string;
      name: string;
      file: { id: number; name: string };
      specific_clauses_to_check: string;
      language: string;
    } = await response.json();

    return new ComparisonChecklistPredefinedTemplate(
      body.id,
      body.name,
      body.file,
      body.specific_clauses_to_check,
      body.language
    );
  };

  const deleteComparisonChecklistPredefined = async (id: string | number) => {
    await fetch(
      `${backendUrl}/api/delete_comparison_checklist_predefined_template/${id}`,
      {
        method: "DELETE",
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
  };

  const editComparisonChecklistPredefined = async (
    id: string | number,
    body: {
      name: string;
      file: string | number;
      specific_clauses_to_check: string;
      language: string;
    }
  ) => {
    const formData = new FormData();
    formData.append("team", mainContext?.teamSelected?.id.toString()!);
    formData.append("name", body.name);
    formData.append("file", body.file.toString());
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    await fetch(
      `${backendUrl}/api/edit_comparison_checklist_predefined_template/${id}`,
      {
        method: "PATCH",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
  };

  const createComparisonChecklistPredefined = async (body: {
    name: string;
    file: string | number;
    specific_clauses_to_check: string;
    language: string;
  }) => {
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("file", body.file.toString());
    formData.append(
      "specific_clauses_to_check",
      body.specific_clauses_to_check
    );
    formData.append("language", body.language);
    const response = await fetch(
      `${backendUrl}/api/create_comparison_checklist_predefined_template/${mainContext?.teamSelected?.id}`,
      {
        method: "POST",
        body: formData,
        headers: { "X-CSRFToken": csrfToken ?? "" },
        credentials: "include",
      }
    );
    const parsedResponse: { id: string | number } = await response.json();

    return parsedResponse.id;
  };

  return (
    <TemplatesContext.Provider
      value={{
        fetchAllMetadata,
        fetchMetadata,
        deleteMetadata,
        editMetadata,
        createMetadata,
        fetchAllObligations,
        fetchObligation,
        deleteObligation,
        editObligation,
        createObligation,
        fetchAllRisks,
        fetchRisk,
        deleteRisk,
        editRisk,
        createRisk,
        fetchAllSummarizations,
        fetchSummarization,
        deleteSummarization,
        editSummarization,
        createSummarization,
        fetchAllComparison,
        fetchComparisonPlaybook,
        deleteComparisonPlaybook,
        editComparisonPlaybook,
        createComparisonPlaybook,
        fetchComparisonRegulationCustom,
        deleteComparisonRegulationCustom,
        editComparisonRegulationCustom,
        createComparisonRegulationCustom,
        fetchComparisonContract,
        deleteComparisonContract,
        editComparisonContract,
        createComparisonContract,
        fetchComparisonRedlining,
        deleteComparisonRedlining,
        editComparisonRedlining,
        createComparisonRedlining,
        fetchComparisonChecklistCustom,
        deleteComparisonChecklistCustom,
        editComparisonChecklistCustom,
        createComparisonChecklistCustom,
        fetchComparisonRegulationPredefined,
        deleteComparisonRegulationPredefined,
        editComparisonRegulationPredefined,
        createComparisonRegulationPredefined,
        fetchComparisonChecklistPredefined,
        deleteComparisonChecklistPredefined,
        editComparisonChecklistPredefined,
        createComparisonChecklistPredefined,
      }}
    >
      {props.children}
    </TemplatesContext.Provider>
  );
};

export default TemplatesContext;
