import { Dropdown, MenuProps } from "antd";
import { useContext } from "react";
import { FaQuestion } from "react-icons/fa6";
import { GrHelpBook } from "react-icons/gr";
import { IoIosHelpCircleOutline } from "react-icons/io";

import MainContext from "../../../../contexts/MainContext";
import styles from "./Help.module.css";

const Help = () => {
  const mainContext = useContext(MainContext);

  const items: MenuProps["items"] = [
    {
      label: (
        <p className={styles.list_item}>
          <GrHelpBook /> Download 'DocuMentor User Guide'
        </p>
      ),
      key: "0",
      onClick: mainContext?.downloadUserGuide,
    },
    {
      label: (
        <p className={styles.list_item}>
          <FaQuestion /> Videos and Use Cases
        </p>
      ),
      key: "1",
      onClick: () => window.open("https://resources.deloitte.com/:f:/s/DocuMentor-Product/EndLH50Hqo5GtNy1vCSQVcUBf72DUvxeW4ht8AhMp_3bEw", "_blank"),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
      <IoIosHelpCircleOutline style={{ width: 28, height: 28 }} />
    </Dropdown>
  );
};

export default Help;
