import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import { AiOutlineDisconnect } from "react-icons/ai";
import { MdOutlineMoveDown } from "react-icons/md";
import { RiParentLine } from "react-icons/ri";
import { FilesPageTabs } from "../../../../../enums/filesPageTabs.enum";
import { FileActions } from "../enums/fileActions.enum";
import { IconType } from "react-icons";

type fileActionsButtonDataMap = {
  [key in FileActions]: {
    icon: IconType;
    title: string;
    inactiveOn?: FilesPageTabs[];
  };
};

const fileActionsButtonData: fileActionsButtonDataMap = {
  [FileActions.MoveTo]: {
    icon: MdOutlineMoveDown,
    title: "Move to",
    inactiveOn: [FilesPageTabs.All],
  },
  [FileActions.MakeAParent]: {
    icon: RiParentLine,
    title: "Make a parent",
    inactiveOn: [FilesPageTabs.All],
  },
  [FileActions.Disconnect]: {
    icon: AiOutlineDisconnect,
    title: "Disconnect",
    inactiveOn: [FilesPageTabs.All],
  },
  [FileActions.Export]: {
    icon: MdOutlineFileDownload,
    title: "Export",
  },
  [FileActions.Delete]: {
    icon: MdDeleteOutline,
    title: "Delete",
  },
};

export default fileActionsButtonData;
