import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Checkbox, List, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { UploadedFile } from "../../../../../../interfaces/uploadedFile.interface";
import MainContext from "../../../../../../contexts/MainContext";
import FilesContext from "../../../../../../contexts/FilesContext";
import { SortOrders } from "../../../../enums/sortOrders.enum";
import SortFunctions from "../../helpers/sortFunctions";
import useEffectWithIntervalAndCondition from "../../helpers/useEffectWithIntervalAndCondition";

import styles from "./FilesBar.module.css";

interface FilesBarProps {
  searchText: string;
  sortOrder: SortOrders;
}

const FilesBar: React.FC<FilesBarProps> = ({ searchText, sortOrder }) => {
  const { projectId } = useParams<{
    projectId: string;
  }>();

  const filesContext = useContext(FilesContext);
  const mainContext = useContext(MainContext);

  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(
    null
  );

  useEffectWithIntervalAndCondition({
    logic: () => {
      if (projectId && mainContext?.teams.length !== 0) {
        filesContext?.getProjectFiles(projectId);
      } else {
        filesContext?.setProjectFiles([]);
      }
      filesContext?.setAllTabSelectedFiles([]);
    },
    dependencies: [projectId, filesContext?.isAnyFileProcessing],
    interval: 10000,
    condition: Boolean(filesContext?.isAnyFileProcessing),
  });

  const isIndeterminate =
    filesContext?.allTabSelectedFiles.length! > 0 &&
    filesContext?.allTabSelectedFiles.length! <
      filesContext?.projectFiles.length!;

  const handleShiftClick = (
    file: UploadedFile,
    index: number,
    isChecked: boolean
  ) => {
    if (lastSelectedIndex === null) {
      onSelect(file, isChecked);
      setLastSelectedIndex(index);
      return;
    }

    const start = Math.min(lastSelectedIndex, index);
    const end = Math.max(lastSelectedIndex, index);

    const filteredFiles =
      filesContext?.projectFiles
        .filter((file) => !file.is_processing)
        .filter((file) =>
          (file.folder_path ? `${file.folder_path}/${file.name}` : file.name)
            .toLowerCase()
            .includes(searchText.toLowerCase())
        )
        .sort(SortFunctions[sortOrder]) || [];

    const filesToSelect = filteredFiles.slice(start, end + 1);

    if (isChecked) {
      filesContext?.setAllTabSelectedFiles((prev) => {
        const newSelection = [...prev];
        filesToSelect.forEach((file) => {
          if (!newSelection.find((f) => f.id === file.id)) {
            newSelection.push(file);
          }
        });
        return newSelection;
      });
    } else {
      filesContext?.setAllTabSelectedFiles((prev) =>
        prev.filter((file) => !filesToSelect.find((f) => f.id === file.id))
      );
    }
  };

  const onSelect = (
    file: UploadedFile,
    isChecked: boolean,
    index?: number,
    event?: MouseEvent
  ) => {
    if (event?.shiftKey && index) {
      handleShiftClick(file, index, isChecked);
    } else {
      filesContext?.setAllTabSelectedFiles((prev) =>
        isChecked ? [...prev, file] : prev.filter(({ id }) => id !== file.id)
      );
      setLastSelectedIndex(index ? index : null);
    }
  };

  const onSelectAll = () => {
    const filesWithoutProcessing = filesContext?.projectFiles.filter(
      (file) => !file.is_processing
    )!;
    if (
      filesContext?.allTabSelectedFiles.length === filesWithoutProcessing.length
    ) {
      filesContext?.setAllTabSelectedFiles([]);
    } else {
      filesContext?.setAllTabSelectedFiles(filesWithoutProcessing);
    }
  };

  const renderFileName = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex === -1) return fileName;

    return (
      <>
        <span>{fileName.substring(0, lastDotIndex)}</span>
        <span className={styles.file_extension}>
          {fileName.substring(lastDotIndex)}
        </span>
      </>
    );
  };

  return (
    <>
      <div className={styles.files_bar}>
        <>
          <div className={styles.files_list_title}>
            <Checkbox
              indeterminate={isIndeterminate}
              checked={
                filesContext?.allTabSelectedFiles.length ===
                filesContext?.projectFiles.length
              }
              onChange={onSelectAll}
            />
            <span className={styles.files_list_title_label}>All files</span>
          </div>

          <List
            className={styles.items_container}
            dataSource={filesContext?.projectFiles
              .filter((file) =>
                (file.folder_path
                  ? `${file.folder_path}/${file.name}`
                  : file.name
                )
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              )
              .sort(SortFunctions[sortOrder])}
            renderItem={(file, index) => (
              <List.Item
                className={`${styles.files_list_item} 
                  ${
                    filesContext?.allTabSelectedFiles
                      .map(({ id }) => id)
                      .includes(file.id) && styles.files_list_item_selected
                  } ${file.is_processing && styles.processing_file}`}
              >
                {file.is_processing ? (
                  <div className={styles.file_process}>
                    <div className={styles.file_process_spinner}>
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 14 }} spin />
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <Checkbox
                    className={`${styles.file_checkbox} ${
                      filesContext?.allTabSelectedFiles
                        .map(({ id }) => id)
                        .includes(file.id) && styles.file_checkbox_checked
                    }`}
                    checked={filesContext?.allTabSelectedFiles
                      .map(({ id }) => id)
                      .includes(file.id)}
                    onChange={(e: CheckboxChangeEvent) =>
                      onSelect(file, e.target.checked, index, e.nativeEvent)
                    }
                  />
                )}

                {file.is_processing ? (
                  <span className={styles.files_list_item_name}>
                    {file.name}
                  </span>
                ) : (
                  <>
                    <span
                      className={`${styles.files_list_item_name} ${styles.preview_button}`}
                      onClick={async (e) => {
                        e.stopPropagation();
                        mainContext?.setTextViewContent({
                          text: undefined,
                        });
                        mainContext?.setIsTextViewVisible(true);

                        let textResponse = await mainContext?.get_doc_text(
                          file.name,
                          ""
                        );
                        mainContext?.setTextViewContent({
                          text: textResponse["text"],
                        });
                      }}
                    >
                      <span className={styles.files_list_item_name}>
                        {file.folder_path ? (
                          <div className={styles.file_path_structure}>
                            <div className={styles.folder_path}>
                              {file.folder_path}
                            </div>
                            <div className={styles.file_name}>
                              {renderFileName(file.name)}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.file_name}>
                            {renderFileName(file.name)}
                          </div>
                        )}
                      </span>
                    </span>
                  </>
                )}
              </List.Item>
            )}
            split={false}
          />
        </>
      </div>
    </>
  );
};

export default FilesBar;
