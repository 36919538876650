import React, { useState } from "react";
import { Tooltip, Upload, UploadFile } from "antd";
import {
  MdOutlineDriveFolderUpload,
  MdOutlineUploadFile,
} from "react-icons/md";
import UploadFilesModal from "../UploadFilesModal/UploadFilesModal";
import acceptedFileTypes from "../../helpers/acceptedFileTypes";

import styles from "./FilesUploader.module.css";

interface UploadChangeParam {
  file: UploadFile;
  fileList: UploadFile[];
  event?: { percent: number };
}

const FilesUploader: React.FC = () => {
  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileChange = ({ fileList }: UploadChangeParam) => {
    const updatedFileList = fileList.map((file) => {
      const relativePath = file.originFileObj?.webkitRelativePath || file.name;

      return {
        ...file,
        name: relativePath,
      };
    });

    setFilesToUpload(updatedFileList);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className={styles.uploaders_container}>
        <Tooltip
          overlayClassName={styles.uploader_tooltip}
          title={
            <div className={styles.title_container}>
              <p className={styles.hint}>
                You can also drop files here directly.
              </p>
              <p>Accepted files</p>
              <p className={styles.accepted_types}>{acceptedFileTypes}</p>
            </div>
          }
          placement="bottom"
        >
          <Upload.Dragger
            multiple
            fileList={[]}
            className={styles.file_upload_dragger}
            accept={acceptedFileTypes}
            beforeUpload={() => false}
            onChange={handleFileChange}
          >
            <p className={styles.file_upload_dragger_text}>
              <MdOutlineUploadFile
                className={styles.file_upload_dragger_icon}
              />
              Upload files
            </p>
          </Upload.Dragger>
        </Tooltip>

        <Tooltip
          overlayClassName={styles.uploader_tooltip}
          title={
            <div className={styles.title_container}>
              <p className={styles.hint}>
                All matching files from folder and subfolders will be added.
              </p>
              <p>Accepted files</p>
              <p className={styles.accepted_types}>{acceptedFileTypes}</p>
            </div>
          }
          placement="bottom"
        >
          <Upload.Dragger
            multiple
            directory
            fileList={[]}
            className={styles.file_upload_dragger}
            accept={acceptedFileTypes}
            beforeUpload={() => false}
            onChange={handleFileChange}
          >
            <p className={styles.file_upload_dragger_text}>
              <MdOutlineDriveFolderUpload
                className={styles.file_upload_dragger_icon}
              />
              Upload folder
            </p>
          </Upload.Dragger>
        </Tooltip>
      </div>

      <UploadFilesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        files={filesToUpload}
        setFiles={setFilesToUpload}
      />
    </>
  );
};

export default FilesUploader;
