import React, { ReactNode, createContext, useState } from "react";
import { UploadedFile } from "../interfaces/uploadedFile.interface";
import { ToolTypes } from "../enums/toolTypes.enum";

interface ToolsContextType {
  selectedFiles: UploadedFile[];
  setSelectedFiles: (
    callbackOrFiles:
      | ((prevState: UploadedFile[]) => UploadedFile[])
      | UploadedFile[]
  ) => void;
  selectedTools: ToolTypes[];
  setSelectedTools: (
    callbackOrFiles: ((prevState: ToolTypes[]) => ToolTypes[]) | ToolTypes[]
  ) => void;
}

const ToolsContext = createContext<ToolsContextType | undefined>(undefined);

interface ToolsProviderProps {
  children: ReactNode;
}

export const ToolsProvider: React.FC<ToolsProviderProps> = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]);
  const [selectedTools, setSelectedTools] = useState<ToolTypes[]>([]);

  return (
    <ToolsContext.Provider
      value={{
        selectedFiles,
        setSelectedFiles,
        selectedTools,
        setSelectedTools,
      }}
    >
      {children}
    </ToolsContext.Provider>
  );
};

export default ToolsContext;
