import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { MainProvider } from "./contexts/MainContext";
import { TeamsProvider } from "./contexts/TeamsContext";
import { TemplatesProvider } from "./contexts/TemplatesContext";
import { TypesProvider } from "./contexts/ToolInputsContext";
import { ResultsProvider } from "./contexts/ResultsContext";
import { ToolsProvider } from "./contexts/ToolsContext";
import { ToastProvider } from "./contexts/ToastContext";
import { FilesProvider } from "./contexts/FilesContext";
import MainPage from "./pages/MainPage/MainPage";

const App: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AuthProvider>
      <ToastProvider>
        <MainProvider>
          <TeamsProvider>
            <TypesProvider>
              <TemplatesProvider>
                <FilesProvider>
                  <ToolsProvider>
                    <ResultsProvider>{children}</ResultsProvider>
                  </ToolsProvider>
                </FilesProvider>
              </TemplatesProvider>
            </TypesProvider>
          </TeamsProvider>
        </MainProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default App;
