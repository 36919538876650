import { UploadFile } from "antd/lib/upload/interface";
import { GetProp, UploadProps } from "antd";
import Project from "../../../data/Project";

const backendUrl = process.env.REACT_APP_API_URL ?? "";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const extractMetadata = async (
  csrfToken: string,
  ids: Array<string | number>,
  metadata: Array<{
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    metadata: metadata,
    language: language ?? "English",
  });
  const responseData = fetch(`${backendUrl}/api/extract_metadata/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const extractMetadataManyToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  result_name: string,
  metadata: Array<{
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    result_name,
    metadata: metadata,
    language: language ?? "English",
  });
  const responseData = fetch(
    `${backendUrl}/api/extract_metadata_with_one_result/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
        "Content-Type": "application/json",
      },
      body,
    }
  );

  return responseData;
};

export const extractObligations = async (
  csrfToken: string,
  ids: Array<string | number>,
  obligations: Array<{ name: string; description: string }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    obligations: obligations,
    language: language ?? "English",
  });
  const responseData = fetch(`${backendUrl}/api/extract_obligations/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const identifyRisks = async (
  csrfToken: string,
  ids: Array<string | number>,
  risks: Array<{
    risk: string;
    task: string;
    mitigation: string;
    situation: string;
    example: string;
  }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    risks: risks,
    language: language ?? "English",
  });
  const responseData = fetch(`${backendUrl}/api/identify_risks/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const compareDocumentsPlaybook = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(`${backendUrl}/api/compare_documents_playbook/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });

  return responseData;
};

export const compareDocumentsContract = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(`${backendUrl}/api/compare_documents_contract/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });

  return responseData;
};

export const compareDocumentsRedlining = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(`${backendUrl}/api/compare_documents_redlining/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });

  return responseData;
};

export const compareDocumentsCustomRegulation = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(
    `${backendUrl}/api/compare_documents_custom_regulation/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    }
  );

  return responseData;
};

export const compareDocumentsPredefinedRegulation = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  regulation: string
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("selected_regulation", JSON.stringify(regulation));
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(
    `${backendUrl}/api/compare_documents_predefined_regulation/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    }
  );

  return responseData;
};

export const compareDocumentsCustomChecklist = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(
    `${backendUrl}/api/compare_documents_custom_checklist/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    }
  );

  return responseData;
};

export const compareDocumentsPredefinedChecklist = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  checklist: string
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("selected_checklist", JSON.stringify(checklist));
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(
    `${backendUrl}/api/compare_documents_predefined_checklist/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    }
  );

  return responseData;
};

export const analyzeTimeline = async (
  csrfToken: string,
  ids: Array<string | number>,
  userQuery: string,
  timeline: boolean,
  orderingCriteria: Array<string>,
  language: string,
  outputName: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    user_query: userQuery,
    timeline: timeline,
    ordering_criteria: orderingCriteria,
    language: language ?? "English",
    output_name: outputName,
  });

  const responseData = fetch(`${backendUrl}/api/analyze_timeline/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const draftingDocumentsCustomPromptBased = async (
  csrfToken: string,
  selectedProject: Project,
  custom_prompt: string,
  tone: string,
  creativity: number,
  output_name: string,
  language: string
) => {
  const body = JSON.stringify({
    project: selectedProject!.id,
    custom_prompt,
    tone,
    creativity,
    output_name,
    language,
  });

  const responseData = fetch(`${backendUrl}/api/draft_custom_based/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const draftingDocumentsTemplateBased = async (
  csrfToken: string,
  selectedProject: Project,
  custom_prompt: string,
  template: number | string,
  output_name: string,
  language: string
) => {
  const body = JSON.stringify({
    project: selectedProject!.id,
    template_id: template,
    custom_prompt,
    output_name,
    language,
  });

  const responseData = fetch(`${backendUrl}/api/draft_template_based/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const draftingDocumentsManyToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  custom_prompt: string,
  template: number | string,
  output_name: string,
  languages: Array<string>
) => {
  const body = JSON.stringify({
    file_ids: ids,
    custom_prompt,
    template_id: template,
    output_name,
    languages,
  });

  const responseData = fetch(`${backendUrl}/api/draft_many_to_one/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const draftingDocumentsOneToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  common_files: Array<any>,
  custom_prompt: string,
  template: number | string,
  languages: Array<string> = ["English"]
) => {
  const formData = new FormData();
  ids.forEach((id) => {
    formData.append("file_ids", id.toString());
  });
  common_files.forEach((file) => {
    formData.append("common_files", file);
  });
  formData.append("custom_prompt", custom_prompt ?? "");
  formData.append("template_id", template.toString() ?? "");
  languages.forEach((language) => {
    formData.append("languages", language);
  });

  const responseData = fetch(`${backendUrl}/api/draft_one_to_one/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });

  return responseData;
};

export const translateText = async (
  csrfToken: string,
  ids: Array<string | number>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    language: language,
  });

  const responseData = fetch(`${backendUrl}/api/translate_text/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const extractTransactionData = async (
  csrfToken: string,
  ids: Array<string | number>,
  team: string
) => {
  const endpointMap: { [key: string]: string } = {
    "Polish Transfer Pricing": "extract_transaction_data_pl_tp",
    "Polish BPS": "extract_transaction_data_pl_bps",
    "Czech BPS": "extract_transaction_data_cz_bps",
  };

  const endpoint = endpointMap[team];

  const apiUrl = `${backendUrl}/api/${endpoint}/`;

  const body = JSON.stringify({
    file_ids: ids,
  });

  const responseData = await fetch(apiUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const summarizeDocumentsManyToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  length_of_summary: string,
  custom_prompt: string,
  output_format: string,
  result_name: string,
  languages: Array<string>
) => {
  const body = JSON.stringify({
    result_name,
    file_ids: ids,
    length_of_summary,
    custom_prompt,
    output_format,
    languages,
  });

  const responseData = fetch(
    `${backendUrl}/api/summarize_documents_with_one_result/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
        "Content-Type": "application/json",
      },
      body,
    }
  );

  return responseData;
};

export const summarizeDocumentsOneToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  length_of_summary: string,
  custom_prompt: string,
  output_format: string,
  languages: Array<string>
) => {
  const body = JSON.stringify({
    file_ids: ids,
    length_of_summary,
    custom_prompt,
    output_format,
    languages,
  });

  const responseData = fetch(`${backendUrl}/api/summarize_documents/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};
