import React, { useState } from "react";
import {
  MdOutlineDriveFolderUpload,
  MdOutlineUploadFile,
} from "react-icons/md";
import { Upload, UploadFile } from "antd";
import acceptedFileTypes from "../../../../helpers/acceptedFileTypes";
import UploadFilesModal from "../../../UploadFilesModal/UploadFilesModal";

import styles from "./FilesUploader.module.css";

interface UploadChangeParam {
  file: UploadFile;
  fileList: UploadFile[];
  event?: { percent: number };
}

const FilesUploader: React.FC = () => {
  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileChange = ({ fileList }: UploadChangeParam) => {
    const updatedFileList = fileList.map((file) => {
      const relativePath = file.originFileObj?.webkitRelativePath || file.name;

      return {
        ...file,
        name: relativePath,
      };
    });

    setFilesToUpload(updatedFileList);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className={styles.empty_state_uploaders_container}>
        <Upload.Dragger
          multiple
          fileList={[]}
          accept={acceptedFileTypes}
          beforeUpload={() => false}
          onChange={handleFileChange}
        >
          <p className="ant-upload-drag-icon">
            <MdOutlineUploadFile className={styles.file_upload_dragger_icon} />
          </p>
          <p className="ant-upload-text">
            Click or drag files to this area to upload
          </p>
          <p className="ant-upload-hint">Accepted files: {acceptedFileTypes}</p>
        </Upload.Dragger>

        <Upload.Dragger
          multiple
          directory
          fileList={[]}
          accept={acceptedFileTypes}
          beforeUpload={() => false}
          onChange={handleFileChange}
        >
          <p className="ant-upload-drag-icon">
            <MdOutlineDriveFolderUpload
              className={styles.file_upload_dragger_icon}
            />
          </p>
          <p className="ant-upload-text">
            Click or drag folder to this area to upload content files
          </p>
          <p className="ant-upload-hint">Accepted files: {acceptedFileTypes}</p>
        </Upload.Dragger>
      </div>

      <UploadFilesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        files={filesToUpload}
        setFiles={setFilesToUpload}
      />
    </>
  );
};

export default FilesUploader;
