import React, { useContext } from "react";
import FilesUploader from "./components/FilesUploader/FilesUploader";
import { Tabs } from "antd";
import type { TabsProps } from "antd";

import styles from "./FilesPage.module.css";
import AllTab from "./components/AllTab/AllTab";
import ConnectedTab from "./components/ConnectedTab/ConnectedTab";
import ForApprovalTab from "./components/ForApprovalTab/ForApprovalTab";
import FilesContext from "../../contexts/FilesContext";
import FileActionsBar from "./components/FileActionsBar/FileActionsBar";
import { FilesPageTabs } from "../../enums/filesPageTabs.enum";

const FilesPage: React.FC = () => {
  const filesContext = useContext(FilesContext);

  const items: TabsProps["items"] = [
    {
      key: FilesPageTabs.All,
      label: (
        <span className={styles.tab_label}>
          All ({filesContext?.projectFiles.length})
        </span>
      ),
      children: <AllTab />,
    },
    {
      key: FilesPageTabs.Connected,
      label: <span className={styles.tab_label}>Connected</span>,
      children: <ConnectedTab />,
    },
    {
      key: FilesPageTabs.ForApproval,
      label: <span className={styles.tab_label}>For Approval</span>,
      children: <ForApprovalTab />,
    },
  ];

  const onChange = (key: string) => {
    filesContext?.setFilesPageTabSelected(key as FilesPageTabs);
  };

  return (
    <div className={styles.page}>
      <div className={styles.page_content}>
        <div className={styles.header}>
          <p className={styles.title}>Files</p>
          <div className={styles.uploaders_container}>
            <FilesUploader />
          </div>
        </div>
        <div className={styles.content}>
          <Tabs
            defaultActiveKey={filesContext?.filesPageTabSelected}
            items={items}
            onChange={onChange}
            tabBarGutter={0}
          />
        </div>
      </div>
      <div className={styles.actions_bar_container}>
        <FileActionsBar />
      </div>
    </div>
  );
};

export default FilesPage;
