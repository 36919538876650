import React, { useContext } from "react";
import { Button, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import FilesContext from "../../../../../../contexts/FilesContext";

import styles from "./ConfirmAIConnectionModal.module.css";

interface ConfirmAIConnectionModalProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

const ConfirmAIConnectionModal: React.FC<ConfirmAIConnectionModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const filesContext = useContext(FilesContext);

  return (
    <Modal
      width="30%"
      centered
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={
        <div className={styles.footer}>
          <Button
            key="acception"
            type="primary"
            size="large"
            className={styles.confirm_button}
            onClick={() => setIsOpen(false)}
          >
            Ok, I understand
          </Button>
        </div>
      }
    >
      <div className={styles.content}>
        <InfoCircleOutlined className={styles.icon} />
        <p>
          You have selected {filesContext?.allTabSelectedFiles.length} files for
          connection.
        </p>
        <p>Processing may take some time.</p>
        <p className={styles.bottom_gap}>
          You will receive an in-app notification once the connections are
          successfully established.
        </p>
        <p className={styles.bottom_gap}>
          New connections will appear in the 'For Approval' tab.
        </p>
      </div>
    </Modal>
  );
};

export default ConfirmAIConnectionModal;
