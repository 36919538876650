import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import styles from "./SiteTitle.module.css";
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

interface SiteTitleProps {
  onSideMenuToogle: () => void;
  sideMenuIsOpen: boolean;
}

// Add this constant at the top of your file, outside the component
const FLAG_STYLES = [
  "rainbow_text",
  "czech_flag",
  "polish_flag",
  "hungarian_flag",
  "serbian_flag",
  "ukrainian_flag",
  "latvian_flag",
  "lithuanian_flag",
  "romanian_flag",
] as const;

const SideMenu: React.FC<SiteTitleProps> = ({
  onSideMenuToogle,
  sideMenuIsOpen,
}) => {
  const [showInitialLogo, setShowInitialLogo] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [currentFlag, setCurrentFlag] = useState<string>("");
  const isChristmasPeriod = useMemo(() => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; // getMonth() returns 0-11

    return month === 12 && day >= 4 && day <= 31;
  }, []);
  // Generate a new random flag when hover starts
  const handleMouseEnter = () => {
    const randomIndex = Math.floor(Math.random() * FLAG_STYLES.length);
    setCurrentFlag(FLAG_STYLES[randomIndex]);
    setIsHovered(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInitialLogo(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div
        className={`${styles.side_menu} ${!sideMenuIsOpen && styles.closed}`}
      >
        <div className={styles.page_title_container}>
          {sideMenuIsOpen && (
            <Link className={styles.home_link} to={"/"}>
              <div
                className={styles.page_title}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={() => setIsHovered(false)}
              >
                {isChristmasPeriod ? (
                  <video
                    className={`${styles.page_title_image} ${
                      !showInitialLogo && styles.fade
                    }`}
                    src={require("../../../../../../images/tree.webm")}
                    autoPlay
                    muted
                    loop
                    style={{
                      opacity: showInitialLogo || isHovered ? 1 : 0,
                      transition: "opacity 0.3s ease-in-out",
                      position: "absolute",
                    }}
                  />
                ) : (
                  <img
                    className={`${styles.page_title_image} ${
                      !showInitialLogo && styles.fade
                    }`}
                    src={require("../../../../../../images/documentor_logo.png")}
                    alt="Documentor Logo"
                    style={{
                      opacity: 1,
                      transition: "opacity 0.3s ease-in-out",
                      position: "absolute",
                    }}
                  />
                )}
                <img
                  className={`${styles.page_title_image} ${
                    !showInitialLogo && styles.fade
                  }`}
                  src={require("../../../../../../images/deloitte.png")}
                  alt="Deloitte"
                  style={{
                    borderRadius: "10px",
                    filter: "drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.3))",
                    opacity: !showInitialLogo && !isHovered ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out",
                  }}
                />
                <p
                  className={`${styles.page_title_text} ${
                    isHovered ? styles[currentFlag] : ""
                  }`}
                >
                  Documentor
                </p>
              </div>
            </Link>
          )}
          <div
            onClick={onSideMenuToogle}
            className={`${styles.toggle_menu_button} ${
              !sideMenuIsOpen && styles.closed
            }`}
          >
            <IoIosArrowBack />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
