import { UploadedFile } from "../../../../../interfaces/uploadedFile.interface";
import { SortOrders } from "../../../enums/sortOrders.enum";

type SortFunctionsMap = {
  [key in SortOrders]:
    | ((a: UploadedFile, b: UploadedFile) => number)
    | undefined;
};

const SortFunctions: SortFunctionsMap = {
  [SortOrders.Default]: undefined,
  [SortOrders.Ascending]: (a: UploadedFile, b: UploadedFile) =>
    a.name.localeCompare(b.name),
  [SortOrders.Descending]: (a: UploadedFile, b: UploadedFile) =>
    b.name.localeCompare(a.name),
};

export default SortFunctions;
