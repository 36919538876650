import React, { useContext, useState } from "react";
import FilesBar from "./components/FilesBar/FilesBar";
import FilesUploader from "./components/FilesUploader/FilesUploader";
import FilesContext from "../../../../contexts/FilesContext";
import { SortOrders } from "../../enums/sortOrders.enum";
import ActionHeader from "../ActionHeader/ActionHeader";
import SelectedFilesTable from "./components/SelectedFilesTable/SelectedFilesTable";

import styles from "./AllTab.module.css";

const AllTab: React.FC = () => {
  const filesContext = useContext(FilesContext);

  const [sortOrder, setSortOrder] = useState<SortOrders>(SortOrders.Default);
  const [searchText, setSearchText] = useState("");

  return (
    <div className={styles.all_tab}>
      {filesContext?.projectFiles.length !== 0 ? (
        <>
          <ActionHeader
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <div className={styles.all_tab_content}>
            <FilesBar searchText={searchText} sortOrder={sortOrder} />
            <SelectedFilesTable />
          </div>
        </>
      ) : (
        <FilesUploader />
      )}
    </div>
  );
};

export default AllTab;
