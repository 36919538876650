import { v4 as uuidv4 } from "uuid";
import { UploadedFile } from "../../../interfaces/uploadedFile.interface";

const backendUrl = process.env.REACT_APP_API_URL ?? "";

export const fetchFiles = async (projectId: string | number) => {
  if (!projectId) {
    return [];
  }
  if (
    projectId === "NaN" ||
    projectId === "undefined" ||
    Number.isNaN(projectId)
  ) {
    return [];
  }
  const response = await fetch(`${backendUrl}/api/files/${projectId}`, {
    method: "GET",
    credentials: "include",
  });
  const body = await response.json();
  const fetchedFiles = body.sort((a: UploadedFile, b: UploadedFile) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });

  return fetchedFiles;
};

export const deleteFiles = async (
  csrfToken: string,
  ids: Array<string | number>
) => {
  await fetch(`${backendUrl}/api/delete_files/`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ids }),
  });
};

export const createFiles = async (
  csrfToken: string,
  projectId: string | number,
  files: File[],
  isAnonymise: boolean,
  isHandwritten: boolean,
  isProcessingEnabled: boolean
): Promise<void> => {
  const maxConcurrentUploads = 2; // Adjust as needed

  // Map files to their modified names (replace slashes with hyphens)
  const filesWithModifiedNames = files.map((file) => {
    const relativePath = file.webkitRelativePath || file.name;
    const formattedName = relativePath;
    return { file, formattedName };
  });
  // Queue to manage files with modified names
  const fileQueue = [...filesWithModifiedNames];
  const uploadPromises: Promise<void>[] = [];

  // Function to start the next upload
  const startNextUpload = async (): Promise<void> => {
    if (fileQueue.length === 0) return;

    // Dequeue the next file and its modified name
    const { file, formattedName } = fileQueue.shift() as {
      file: File;
      formattedName: string;
    };

    // Pass the modified name to uploadFileInChunks
    await uploadFileInChunks(
      file,
      formattedName, // Pass the modified file name
      csrfToken,
      projectId, // Use the provided project ID
      isAnonymise,
      isHandwritten,
      isProcessingEnabled
    );

    // Start the next upload
    await startNextUpload();
  };

  // Start initial uploads up to the concurrency limit
  for (let i = 0; i < maxConcurrentUploads; i++) {
    uploadPromises.push(startNextUpload());
  }

  // Wait for all uploads to complete
  await Promise.all(uploadPromises);
};

async function uploadFileInChunks(
  file: File,
  modifiedFileName: string, // Add parameter for modified file name
  csrfToken: string,
  projectId: string | number,
  isAnonymise: boolean,
  isHandwritten: boolean,
  isProcessingEnabled: boolean,
  onProgress?: (progress: number, file: File) => void // Optional progress callback
): Promise<void> {
  const chunkSize = 1024 * 1024; // 1MB per chunk
  const totalChunks = Math.ceil(file.size / chunkSize);
  const fileId = uuidv4(); // Unique identifier for the file

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start = chunkIndex * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append("project", projectId.toString());
    formData.append("isAnonymise", isAnonymise.toString());
    formData.append("isHandwritten", isHandwritten.toString());
    formData.append("isProcessingEnabled", isProcessingEnabled.toString());
    formData.append("chunk", chunk);
    formData.append("fileId", fileId);
    formData.append("chunkIndex", chunkIndex.toString());
    formData.append("totalChunks", totalChunks.toString());
    formData.append("fileName", modifiedFileName); // Use the modified file name here
    formData.append("fileSize", file.size.toString());

    await fetch(`${backendUrl}/api/upload_file_chunk/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    });

    // Update progress
    if (onProgress) {
      onProgress((chunkIndex + 1) / totalChunks, file);
    }
  }
}
