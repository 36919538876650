import React from "react";

import styles from "./EmptyState.module.css";

const EmptyState: React.FC = () => {
  return (
    <div className={styles.empty_state_container}>
      <p className={styles.text}>
        To establish new connection, select the files from the list
      </p>
      <img
        className={styles.arrow_picture}
        src={require("../../../../../../../../images/arrow_left.png")}
        alt="arrow"
      />
    </div>
  );
};

export default EmptyState;
