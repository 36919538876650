import React, { useContext } from "react";
import FilesContext from "../../../../../../contexts/FilesContext";
import type { TableColumnsType } from "antd";
import { Button, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import EmptyState from "./components/EmptyState/EmptyState";

import styles from "./SelectedFilesTable.module.css";

interface SelectedFilesTableData {
  key: React.Key;
  name: string;
  date: string;
}

const SelectedFilesTable: React.FC = () => {
  const filesContext = useContext(FilesContext);

  const columns: TableColumnsType<SelectedFilesTableData> = [
    {
      title: "File name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      width: "50%",
      render: (fileName: string) => (
        <p className={styles.file_name}>{fileName}</p>
      ),
    },
    {
      title: "Creation date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ["ascend", "descend"],
      width: "30%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
      align: "center",
      render: (fileId: number) => (
        <Button
          type="text"
          onClick={() =>
            filesContext?.setAllTabSelectedFiles((prevState) =>
              prevState.filter((file) => file.id !== fileId)
            )
          }
          icon={<CloseOutlined />}
        />
      ),
    },
  ];
  return (
    <>
      {filesContext?.allTabSelectedFiles.length ? (
        <div className={styles.selected_files_table_container}>
          <h5 className={styles.title}>Selected files for connection</h5>
          <p className={styles.sub_title}>
            {filesContext?.allTabSelectedFiles.length} files selected
          </p>

          <Table
            id="without-export-button"
            size="small"
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: false,
            }}
            columns={columns}
            dataSource={filesContext?.allTabSelectedFiles
              .map((file) => ({
                key: file.id,
                name: file.name,
                date: dayjs(file.created_at).format("DD/MM/YYYY"),
                action: file.id,
              }))
              .reverse()}
          />
        </div>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default SelectedFilesTable;
