import React, { useEffect, useState } from "react";
import EmptyState from "./components/EmptyState/EmptyState";
import ActionHeader from "../ActionHeader/ActionHeader";
import { SortOrders } from "../../enums/sortOrders.enum";
import { Checkbox, Collapse, List } from "antd";
import { BsStars } from "react-icons/bs";
import { MdOutlineBackHand } from "react-icons/md";
import { FileOutlined } from "@ant-design/icons";

import styles from "./ConnectedTab.module.css";

const mockConnections = [
  {
    type: "manual",
    files: [
      { id: 1, name: "File1.pdf", main: true },
      { id: 2, name: "File2.pdf", main: false },
      { id: 3, name: "File3.pdf", main: false },
      { id: 4, name: "File4.pdf", main: false },
    ],
  },
  {
    type: "ai",
    files: [
      { id: 16, name: "File16.pdf", main: true },
      { id: 17, name: "File17.pdf", main: false },
      { id: 18, name: "File18.pdf", main: false },
      { id: 19, name: "File19pdf", main: false },
    ],
  },
  {
    type: "manual",
    files: [
      { id: 5, name: "File5.pdf", main: true },
      { id: 6, name: "File6.pdf", main: false },
      { id: 7, name: "File7.pdf", main: false },
    ],
  },
  {
    type: "manual",
    files: [
      { id: 8, name: "File8.pdf", main: true },
      { id: 9, name: "File9.pdf", main: false },
      { id: 10, name: "File10.pdf", main: false },
    ],
  },
  {
    type: "ai",
    files: [
      { id: 11, name: "File11.pdf", main: true },
      { id: 12, name: "File12.pdf", main: false },
      { id: 13, name: "File13.pdf", main: false },
      { id: 14, name: "File14.pdf", main: false },
      { id: 15, name: "File15.pdf", main: false },
    ],
  },
];

const mockSingleFiles = [
  { id: "s1", name: "File1.pdf" },
  { id: "s2", name: "File2.pdf" },
  { id: "s3", name: "File3.pdf" },
  { id: "s4", name: "File4.pdf" },
  { id: "s5", name: "File5.pdf" },
  { id: "s6", name: "File6.pdf" },
  { id: "s7", name: "File7.pdf" },
  { id: "s8", name: "File8.pdf" },
  { id: "s9", name: "File9.pdf" },
  { id: "s10", name: "File10.pdf" },
  { id: "s11", name: "File11.pdf" },
  { id: "s12", name: "File12.pdf" },
  { id: "s13", name: "File13.pdf" },
  { id: "s14", name: "File14.pdf" },
  { id: "s15", name: "File15.pdf" },
  { id: "s16", name: "File16.pdf" },
];

const ConnectedTab: React.FC = () => {
  const [sortOrder, setSortOrder] = useState<SortOrders>(SortOrders.Default);
  const [searchText, setSearchText] = useState("");

  const [mockData, setMockData] = useState<
    {
      key: number;
      label: JSX.Element;
      children: JSX.Element;
    }[]
  >([]);

  useEffect(() => {
    const data = mockConnections.map((connection, index) => {
      const mappedItem = {
        key: index,
        label: (
          <div>
            <Checkbox onChange={(e) => console.log(e.target)}>
              <div className={styles.checkbox_content}>
                {connection.type === "ai" ? (
                  <BsStars color="var(--deloitte-green)" />
                ) : (
                  <MdOutlineBackHand color="var(--deloitte-green)" />
                )}
                {connection.files.find((file) => file.main)?.name}
              </div>
            </Checkbox>
          </div>
        ),
        children: (
          <List
            className={styles.nested_files}
            size="small"
            split={false}
            dataSource={connection.files.filter((file) => !file.main)}
            renderItem={(file) => (
              <List.Item className={styles.list_item}>
                <Checkbox onChange={(e) => console.log(e.target)}>
                  <div className={styles.checkbox_content}>
                    <FileOutlined />
                    {file.name}
                  </div>
                </Checkbox>
              </List.Item>
            )}
          />
        ),
      };
      return mappedItem;
    });
    setMockData(data);
  }, []);

  return (
    <div className={styles.connected_tab_container}>
      {/* <ActionHeader
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <div className={styles.connected_container}>
        <div className={styles.list_container}>
          <h5>List of connections</h5>
          <Collapse
            size="small"
            collapsible="icon"
            bordered={false}
            expandIconPosition="end"
            style={{ background: "transparent" }}
            className={styles.list}
            items={mockData}
          />
        </div>
        <div className={styles.list_container}>
          <h5>Single files</h5>
          <List
            size="small"
            split={false}
            className={styles.list}
            dataSource={mockSingleFiles}
            renderItem={(item) => (
              <List.Item className={styles.list_item}>
                <Checkbox onChange={(e) => console.log(e.target)}>
                  <div className={styles.checkbox_content}>
                    <FileOutlined />
                    {item.name}
                  </div>
                </Checkbox>
              </List.Item>
            )}
          />
        </div>
      </div> */}

      <EmptyState />
    </div>
  );
};

export default ConnectedTab;
