import React, {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useContext,
} from "react";
import { UploadedFile } from "../interfaces/uploadedFile.interface";
import * as FilesService from "../pages/FilesPage/services/files.service";
import MainContext from "./MainContext";
import { FilesPageTabs } from "../enums/filesPageTabs.enum";

interface FilesContextType {
  filesPageTabSelected: FilesPageTabs;
  setFilesPageTabSelected: React.Dispatch<React.SetStateAction<FilesPageTabs>>;
  allTabSelectedFiles: UploadedFile[];
  setAllTabSelectedFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  connectedTabSelectedFiles: UploadedFile[];
  setConnectedTabSelectedFiles: React.Dispatch<
    React.SetStateAction<UploadedFile[]>
  >;
  forApprovalTabSelectedFiles: UploadedFile[];
  setForApprovalTabSelectedFiles: React.Dispatch<
    React.SetStateAction<UploadedFile[]>
  >;

  projectFiles: UploadedFile[];
  setProjectFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  getProjectFiles: (id: string | number) => void;
  isAnyFileProcessing: boolean;
  libraryFiles: UploadedFile[];
  setLibraryFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
}

const FilesContext = createContext<FilesContextType | undefined>(undefined);

interface FilesProviderProps {
  children: ReactNode;
}

export const FilesProvider: React.FC<FilesProviderProps> = ({ children }) => {
  const mainContext = useContext(MainContext);

  const [filesPageTabSelected, setFilesPageTabSelected] =
    useState<FilesPageTabs>(FilesPageTabs.All);

  const [allTabSelectedFiles, setAllTabSelectedFiles] = useState<
    UploadedFile[]
  >([]);
  const [connectedTabSelectedFiles, setConnectedTabSelectedFiles] = useState<
    UploadedFile[]
  >([]);
  const [forApprovalTabSelectedFiles, setForApprovalTabSelectedFiles] =
    useState<UploadedFile[]>([]);

  const [libraryFiles, setLibraryFiles] = useState<UploadedFile[]>([]);
  const [projectFiles, setProjectFiles] = useState<UploadedFile[]>([]);
  const [isAnyFileProcessing, setIsAnyFileProcessing] = useState(
    projectFiles.some((file: UploadedFile) => file.is_processing)
  );

  useEffect(() => {
    setIsAnyFileProcessing(
      projectFiles.some((file: UploadedFile) => file.is_processing)
    );
  }, [projectFiles]);

  useEffect(() => {
    getProjectFiles(Number(mainContext?.projectSelected?.id));
  }, [mainContext?.projectSelected]);

  const getProjectFiles = async (projectId: string | number) => {
    const fetchedFiles = await FilesService.fetchFiles(projectId);
    setProjectFiles(fetchedFiles);
  };

  return (
    <FilesContext.Provider
      value={{
        filesPageTabSelected,
        setFilesPageTabSelected,
        allTabSelectedFiles,
        setAllTabSelectedFiles,
        connectedTabSelectedFiles,
        setConnectedTabSelectedFiles,
        forApprovalTabSelectedFiles,
        setForApprovalTabSelectedFiles,
        projectFiles,
        setProjectFiles,
        getProjectFiles,
        isAnyFileProcessing,
        libraryFiles,
        setLibraryFiles,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};

export default FilesContext;
