import React from "react";
import { MdOutlineApproval } from "react-icons/md";

import styles from "./EmptyState.module.css";

const EmptyState: React.FC = () => {
  return (
    <div className={styles.container}>
      <MdOutlineApproval className={styles.icon} />
      <p className={styles.title}>No connections for approval</p>
      <p>Connections for approval will appear here</p>
    </div>
  );
};

export default EmptyState;
