import React, { useContext, useState } from "react";
import fileActionsButtonData from "./helpers/fileActionsButtonData";
import FilesContext from "../../../../contexts/FilesContext";
import { IconType } from "react-icons";
import { FileActions } from "./enums/fileActions.enum";
import DeleteFilesModal from "../AllTab/components/DeleteFilesModal/DeleteFilesModal";
import { UploadedFile } from "../../../../interfaces/uploadedFile.interface";
import { FilesPageTabs } from "../../../../enums/filesPageTabs.enum";

import styles from "./FileActionsBar.module.css";

type configMap = Partial<{
  [key in FileActions]: {
    disabledWhile: boolean;
    action: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  };
}>;

type barConfigMap = {
  [key in FilesPageTabs]: configMap;
};

const FileActionsBar: React.FC = () => {
  const filesContext = useContext(FilesContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState<UploadedFile[]>([]);

  const renderIconComponent = (icon: IconType) => {
    const IconComponent = icon;
    return <IconComponent className={styles.file_action_icon} />;
  };

  const allTabButtonsConfig: configMap = {
    [FileActions.Export]: {
      disabledWhile: filesContext?.allTabSelectedFiles.length === 0,
      action: (e) => console.log("All Tab Export"),
    },
    [FileActions.Delete]: {
      disabledWhile: filesContext?.allTabSelectedFiles.length === 0,
      action: (e) => {
        e.stopPropagation();
        setFilesToDelete(filesContext?.allTabSelectedFiles!);
        setIsDeleting(true);
      },
    },
  };

  const connectedTabButtonsConfig: configMap = {
    [FileActions.MoveTo]: {
      disabledWhile: filesContext?.connectedTabSelectedFiles.length === 0,
      action: () => console.log("Connected Tab Move To"),
    },
    [FileActions.MakeAParent]: {
      disabledWhile: filesContext?.connectedTabSelectedFiles.length !== 1,
      action: () => console.log("Connected Tab Make A Parent"),
    },
    [FileActions.Disconnect]: {
      disabledWhile: filesContext?.connectedTabSelectedFiles.length === 0,
      action: () => console.log("Connected Tab Disconnect"),
    },
    [FileActions.Export]: {
      disabledWhile: filesContext?.connectedTabSelectedFiles.length === 0,
      action: () => console.log("Connected Tab Export"),
    },
    [FileActions.Delete]: {
      disabledWhile: filesContext?.connectedTabSelectedFiles.length === 0,
      action: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        setFilesToDelete(filesContext?.connectedTabSelectedFiles!);
        setIsDeleting(true);
      },
    },
  };

  const forApprovalTabButtonsConfig: configMap = {
    [FileActions.MoveTo]: {
      disabledWhile: filesContext?.forApprovalTabSelectedFiles.length === 0,
      action: () => console.log("For Approval Tab Move To"),
    },
    [FileActions.MakeAParent]: {
      disabledWhile: filesContext?.forApprovalTabSelectedFiles.length !== 1,
      action: () => console.log("For Approval Tab Make A Parent"),
    },
    [FileActions.Disconnect]: {
      disabledWhile: filesContext?.forApprovalTabSelectedFiles.length === 0,
      action: () => console.log("For Approval Tab Disconnect"),
    },
    [FileActions.Export]: {
      disabledWhile: filesContext?.forApprovalTabSelectedFiles.length === 0,
      action: () => console.log("For Approval Tab Export"),
    },
    [FileActions.Delete]: {
      disabledWhile: filesContext?.forApprovalTabSelectedFiles.length === 0,
      action: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        setFilesToDelete(filesContext?.forApprovalTabSelectedFiles!);
        setIsDeleting(true);
      },
    },
  };

  const barConfig: barConfigMap = {
    [FilesPageTabs.All]: allTabButtonsConfig,
    [FilesPageTabs.Connected]: connectedTabButtonsConfig,
    [FilesPageTabs.ForApproval]: forApprovalTabButtonsConfig,
  };

  return (
    <>
      <div className={styles.file_actions_bar}>
        {Object.entries(fileActionsButtonData).map(([key, buttonData]) => (
          <div
            key={buttonData.title}
            className={`${styles.file_action_button} ${
              buttonData.inactiveOn?.includes(
                filesContext!.filesPageTabSelected
              ) && styles.action_inactive
            } ${
              barConfig[filesContext!.filesPageTabSelected][key as FileActions]
                ?.disabledWhile && styles.action_disabled
            }`}
            onClick={
              barConfig[filesContext!.filesPageTabSelected][key as FileActions]
                ?.action
            }
          >
            {renderIconComponent(buttonData.icon)}
            {buttonData.title}
          </div>
        ))}
      </div>

      <DeleteFilesModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        files={filesToDelete}
      />
    </>
  );
};

export default FileActionsBar;
