import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Modal, Tooltip, UploadFile } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { MdOutlineDataObject } from "react-icons/md";
import getCookie from "../../../../../../helpers/getCookie";
import MainContext from "../../../../../../contexts/MainContext";
import FilesContext from "../../../../../../contexts/FilesContext";
import * as FilesService from "../../../../../FilesPage/services/files.service";

import styles from "./UploadFilesModal.module.css";

interface UploadFilesModalProps {
  isOpen: boolean;
  onClose: () => void;
  files: UploadFile[];
  setFiles: (files: UploadFile[]) => void;
}

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({
  isOpen,
  onClose,
  files,
  setFiles,
}) => {
  const mainContext = useContext(MainContext);
  const filesContext = useContext(FilesContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isAnonymise, setIsAnonymise] = useState(false);
  const [isHandwritten, setIsHandwritten] = useState(false);
  const [isProcessingEnabled, setIsProcessingEnabled] = useState(true);

  const csrfToken = getCookie("csrftoken") ?? "";

  useEffect(() => {
    mainContext?.setIsTextViewVisible(false);
  }, [isOpen]);

  useEffect(() => {
    if (files.length === 0) {
      onClose();
    }
    return () => {
      setIsAnonymise(false);
      setIsHandwritten(false);
      // setIsProcessingEnabled(true);
    };
  }, [files]);

  const onSubmit = async () => {
    setIsLoading(true);
    if (mainContext?.projectSelected && files) {
      // Map the files with formatted names
      const updatedFiles = files.map((file) => {
        const relativePath =
          file.originFileObj?.webkitRelativePath || file.name;
        const formattedName = relativePath;

        // Create a new File instance with the updated name and assign it to originFileObj
        const updatedFile = new File([file.originFileObj!], formattedName, {
          type: file.type,
        });

        return {
          ...file,
          name: formattedName, // Update the displayed name
          originFileObj: updatedFile, // Update the file object with modified name
        };
      });

      // Update the files state to ensure we're sending the modified file names
      setFiles(updatedFiles as UploadFile[]);

      // Prepare the files for submission
      let dataTransfer = new DataTransfer();
      updatedFiles.forEach((file) =>
        dataTransfer.items.add(file.originFileObj!)
      );

      const mappedFiles: File[] = Array.from(dataTransfer.files);

      await FilesService.createFiles(
        csrfToken,
        mainContext.projectSelected.id,
        mappedFiles,
        isAnonymise,
        isHandwritten,
        isProcessingEnabled
      );

      filesContext?.getProjectFiles(mainContext.projectSelected.id);
    }
    setIsLoading(false);

    onClose();
  };
  return (
    <Modal
      width="80%"
      centered
      open={isOpen}
      title={<p className={styles.title}>Adding files</p>}
      onOk={onSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" className={styles.close_button} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          loading={isLoading}
          onClick={onSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <div className={styles.content}>
        <div className={styles.content_part}>
          <p className={styles.content_header}>Files</p>
          <div className={styles.files_container}>
            {files.map((item, index) => (
              <p key={index} className={styles.content_item}>
                <span className={styles.content_item_data}>{item.name}</span>
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setFiles(files?.filter((file) => file.uid !== item.uid));
                  }}
                />
              </p>
            ))}
          </div>
        </div>

        <div className={styles.middle_icon}>
          <MdOutlineDataObject />
        </div>

        <div className={styles.content_part}>
          <p className={styles.content_header}>Options</p>
          <p key="anonymise" className={styles.content_item}>
            <Tooltip
              overlayInnerStyle={{ width: "400px" }}
              title="Anonymize each file after upload by deleting person names, company names, emails, phone numbers, and addresses. The original file won't be saved in the app."
            >
              <Checkbox
                checked={isAnonymise}
                onChange={(event) => setIsAnonymise(event.target.checked)}
              >
                Anonymize
              </Checkbox>
            </Tooltip>
          </p>
          <p key="handwritten" className={styles.content_item}>
            <Tooltip
              overlayInnerStyle={{ width: "400px" }}
              title="Extract handwritten text from images and PDFs. The PDF limit is 5 pages."
            >
              <Checkbox
                checked={isHandwritten}
                onChange={(event) => setIsHandwritten(event.target.checked)}
              >
                Process Handwriting
              </Checkbox>
            </Tooltip>
          </p>
          <p key="processingEnabled" className={styles.content_item}>
            <Tooltip
              overlayInnerStyle={{ width: "400px" }}
              title="Generate a concise summary of each file after upload. This improves the quality of the AI Agent and Drafting."
            >
              <Checkbox
                checked={isProcessingEnabled}
                onChange={(event) =>
                  setIsProcessingEnabled(event.target.checked)
                }
              >
                Generate Summary
              </Checkbox>
            </Tooltip>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFilesModal;
