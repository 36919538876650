import React from "react";
import { ImFilesEmpty } from "react-icons/im";

import styles from "./EmptyState.module.css";

const EmptyState: React.FC = () => {
  return (
    <div className={styles.container}>
      <ImFilesEmpty className={styles.icon} />
      <p className={styles.title}>No connections established</p>
      <p>
        To make new connections go to 'All' tab and select the files for
        connections
      </p>
    </div>
  );
};

export default EmptyState;
