import React, { useContext, useState } from "react";
import { Button, Tooltip } from "antd";
import {
  BsFiletypeDocx,
  BsFiletypeJson,
  BsFiletypePdf,
  BsFiletypeXlsx,
} from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { TableData } from "../../interfaces/tableData.interface";
import exportFileFunctions from "../../helpers/exportFileFunctions";
import { ResultTypes } from "../../enums/resultTypes.enum";
import DeleteResultsModal from "../DeleteResultsModal/DeleteResultsModal";
import ResultsContext from "../../../../contexts/ResultsContext";
import getCookie from "../../../../helpers/getCookie";
import ResultFeedback from "../ResultFeedback/ResultFeedback";
import * as ResultsService from "../../services/results.service";

import styles from "./ResultActions.module.css";

interface ResultActionsProps {
  record: TableData;
  resultType: ResultTypes;
}

const ResultActions: React.FC<ResultActionsProps> = ({
  record,
  resultType,
}) => {
  const resultsContext = useContext(ResultsContext);

  const csrfToken = getCookie("csrftoken") ?? "";

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await ResultsService.deleteResults(csrfToken, [record.key]);
    setIsDeleting(false);
    resultsContext?.setFetchingToggle((prev) => !prev);
    setIsOpenDeleteModal(false);
  };

  const handleCancel = () => {
    setIsOpenDeleteModal(false);
  };

  return (
    <>
      <div className={styles.result_actions}>
        {resultType !== ResultTypes.Drafting &&
          resultType !== ResultTypes.Translation &&
          resultType !== ResultTypes.Timeline &&
          resultType !== ResultTypes.Summarization && (
            <>
              {/* <Tooltip title="Export as CSV"><Button type='link' icon={<BsFiletypeCsv size="18" />} onClick={e => { e.stopPropagation(); mainContext?.exportMetadataToCSV(record.key.toString()); }} /></Tooltip>
          <div style={{ width: 6 }} /> */}
              <Tooltip title="Export as XLSX">
                <Button
                  type="link"
                  icon={<BsFiletypeXlsx size="18" />}
                  onClick={(e) => {
                    e.stopPropagation();
                    exportFileFunctions[resultType].xlsx(record.key.toString());
                  }}
                />
              </Tooltip>

              {resultType !== ResultTypes.Transaction && (
                <Tooltip title="Export as DOCX">
                  <Button
                    type="link"
                    icon={<BsFiletypeDocx size="18" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      exportFileFunctions[resultType].docx(
                        record.key.toString()
                      );
                    }}
                  />
                </Tooltip>
              )}

              {resultType !== ResultTypes.Transaction && (
                <Tooltip title="Export as PDF">
                  <Button
                    type="link"
                    icon={<BsFiletypePdf size="18" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      exportFileFunctions[resultType].pdf(
                        record.key.toString()
                      );
                    }}
                  />
                </Tooltip>
              )}

              {resultType !== ResultTypes.Transaction && (
                <Tooltip title="Export as JSON">
                  <Button
                    type="link"
                    icon={<BsFiletypeJson size="18" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      exportFileFunctions[resultType].json(
                        record.key.toString()
                      );
                    }}
                  />
                </Tooltip>
              )}

              <div className={styles.vertical_divider} />

              {/* <Tooltip title="Share"><Button type="link" icon={<FiShare2 size="20" />} onClick={e => { e.stopPropagation(); alert('In development ...') }} /></Tooltip> */}
            </>
          )}
        <Tooltip title="Delete">
          <Button
            type="link"
            icon={<MdDeleteOutline color="var(--beautiful-red)" size="20" />}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenDeleteModal(true);
            }}
          />
        </Tooltip>

        <div className={styles.vertical_divider} />

        <ResultFeedback
          resultId={record.key.toString()}
          toolSelected={resultType}
        />
      </div>

      <DeleteResultsModal
        isOpen={isOpenDeleteModal}
        isDeleting={isDeleting}
        onDeleteResults={handleDelete}
        onCancelDelete={handleCancel}
      />
    </>
  );
};

export default ResultActions;
